import { useLocation } from "react-router-dom";
import { NAVIGATION } from "../../../consts";
import { Navigation } from "../../../types";

const usePageTitle = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  return (NAVIGATION[path as keyof Navigation])?.title;
};

export default usePageTitle;