import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    window.pageYOffset > 400 ? setIsVisible(true) : setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Button
      isIconOnly
      radius="full"
      size="lg"
      color="primary"
      variant="flat"
      className="fixed bottom-4 left-4 z-1"
      onClick={onClick}
    >
      <FaArrowUp />
    </Button>
  );
};

export default ScrollToTopButton;