import React, { useRef, useEffect, FunctionComponent } from 'react'
import { TelegramLoginButtonProps } from '../../../types'

const TelegramLoginWidget: FunctionComponent<TelegramLoginButtonProps> = ({
  botName,
  buttonSize = 'large',
  cornerRadius,
  requestAccess,
  usePic = true,
  dataOnAuth,
  dataAuthUrl,
  lang = 'en',
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!!dataAuthUrl === !!dataOnAuth) {
      throw new Error(
        'One of this props should be defined: dataAuthUrl (Redirect URL), dataOnAuth (callback fn) should be defined.'
      )
    }

    if (dataOnAuth) {
      ;(window as any).telegramLoginWidgetCb = dataOnAuth
    }

    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.async = true

    const attributes = {
      'data-telegram-login': botName,
      'data-size': buttonSize,
      'data-radius': cornerRadius,
      'data-request-access': requestAccess ? 'write' : undefined,
      'data-userpic': usePic,
      'data-lang': lang,
      'data-auth-url': dataAuthUrl,
      'data-onauth': 'telegramLoginWidgetCb(user)',
    }

    for (const [k, v] of Object.entries(attributes)) {
      v !== undefined && script.setAttribute(k, `${v}`)
    }

    containerRef.current!.appendChild(script)

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ''
      }
      if ((window as any).telegramLoginWidgetCb) {
        delete (window as any).telegramLoginWidgetCb
      }
    }
  })

  return <div ref={containerRef}></div>
}

export default TelegramLoginWidget
