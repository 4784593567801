import { LinkType } from "./types";

export const getInitials = (title: string) => {
  const words = title.split(' ');

  return words.length > 1 ? words[0].charAt(0) + words[1].charAt(0) : words[0].charAt(0);
};

export const getApiUrl = (): string => {
  return window.location.host.startsWith('localhost') ? 'http://localhost:3000/api' : '/api'
}

export const sortByDate = (first: LinkType, second: LinkType) => {
  const firstPublishDate = first.publishDate?.toString();
  const secondPublishDate = second.publishDate?.toString();

  const firstPublishDateTime = new Date(firstPublishDate ? firstPublishDate : 0).getTime()
  const secondPublishDateTime = new Date(secondPublishDate ? secondPublishDate : 0).getTime()

  if (firstPublishDateTime === secondPublishDateTime ) {
    const firstCreatedDate = first.createdAt?.toString();
    const secondCreatedDate = second.createdAt?.toString();

    const firstCreatedDateTime = new Date(firstCreatedDate ? firstCreatedDate : 0).getTime()
    const secondCreatedDateTime = new Date(secondCreatedDate ? secondCreatedDate : 0).getTime()

    return firstCreatedDateTime < secondCreatedDateTime ? -1 : 1;
  }

  return firstPublishDateTime < secondPublishDateTime ? -1 : 1;
};