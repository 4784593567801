import React, { useMemo } from "react";
import { Button, SortDescriptor, Switch } from "@nextui-org/react";
import { BiLink, BiPencil } from "react-icons/bi";
import { PiCoins, PiHash } from "react-icons/pi";
import { RiUserLine, RiUserStarLine } from "react-icons/ri";
import { LiaCoinsSolid} from "react-icons/lia";
import FilterInput from "../components/ChannelLinksTable/components/FilterInput";
import { TEXTS } from "../../../consts";
import { DateFilterValue } from "../../../types";
import DateRange from "../../../components/DateRange/DateRange";
import SortingSelects from "../components/ChannelLinksTable/components/SortingSelects";
import useBreakPoints from "../../../hooks/useBreakPoints";

type Options = {
  dateFilterValue: DateFilterValue;
  onDateSearchChange: (value: DateFilterValue) => void;
  titleFilterValue: string;
  onTitleSearchChange: (value: string) => void;
  tagsFilterValue: string;
  onTagsSearchChange: (value: string) => void;
  linkFilterValue: string;
  onLinkSearchChange: (value: string) => void;
  budgetFromFilterValue: string;
  onBudgetFromSearchChange: (value: string) => void;
  budgetToFilterValue: string;
  onBudgetToSearchChange: (value: string) => void;
  subscribersFromFilterValue: string;
  onSubscribersFromSearchChange: (value: string) => void;
  subscribersToFilterValue: string;
  onSubscribersToSearchChange: (value: string) => void;
  showArchivedLinks: boolean;
  onShowArchivedLinksChanges: (value: boolean) => void;
  showAllOwnersLinks: boolean;
  onshowAllOwnersLinksChanges: (value: boolean) => void;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (sortDescriptor: SortDescriptor) => void;
};

const useTableTopContent = ({
  dateFilterValue,
  onDateSearchChange,
  titleFilterValue,
  onTitleSearchChange,
  tagsFilterValue,
  onTagsSearchChange,
  linkFilterValue,
  onLinkSearchChange,
  budgetFromFilterValue,
  onBudgetFromSearchChange,
  budgetToFilterValue,
  onBudgetToSearchChange,
  subscribersFromFilterValue,
  onSubscribersFromSearchChange,
  subscribersToFilterValue,
  onSubscribersToSearchChange,
  showArchivedLinks,
  onShowArchivedLinksChanges,
  showAllOwnersLinks,
  onshowAllOwnersLinksChanges,
  sortDescriptor,
  setSortDescriptor
}: Options) => {
  const { channelTable: { filters, buttons,  } } = TEXTS;

  const { isMobile, isTablet } = useBreakPoints();

  const onClearFiltersClick = () => {
    onDateSearchChange({ startDate: undefined, endDate: undefined });
    onTitleSearchChange("");
    onTagsSearchChange("");
    onLinkSearchChange("");
    onBudgetFromSearchChange("");
    onBudgetToSearchChange("");
    onSubscribersFromSearchChange("");
    onSubscribersToSearchChange("");
    onShowArchivedLinksChanges(false);
    onshowAllOwnersLinksChanges(false);
  };

  return useMemo(() => {
    return (
      <div>
        <div className="mb-2 md:mb-3 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2 md:gap-3">
          <DateRange dateFilterValue={dateFilterValue} onDateSearchChange={onDateSearchChange} />
          <FilterInput
            label={filters.title.label}
            value={titleFilterValue}
            placeholder={filters.title.placeholder}
            IconComponent={BiPencil}
            onValueChange={onTitleSearchChange}
          />
          <FilterInput
            label={filters.tags.label}
            value={tagsFilterValue}
            placeholder={filters.tags.placeholder}
            IconComponent={PiHash}
            onValueChange={onTagsSearchChange}
          />
          <FilterInput
            label={filters.link.label}
            value={linkFilterValue}
            placeholder={filters.link.placeholder}
            IconComponent={BiLink}
            onValueChange={onLinkSearchChange}
          />
          <div className="flex flex-col">
            <label className="text-xs font-medium mb-1.5">
              {filters.budget.label}
            </label>
            <div className="flex gap-2">
              <FilterInput
                value={budgetFromFilterValue}
                placeholder={filters.from}
                IconComponent={PiCoins}
                onValueChange={onBudgetFromSearchChange}
                isNumber
              />
              <FilterInput
                value={budgetToFilterValue}
                placeholder={filters.to}
                IconComponent={LiaCoinsSolid}
                onValueChange={onBudgetToSearchChange}
                isNumber
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-xs font-medium mb-1.5">
              {filters.subscribers.label}
            </label>
            <div className="flex gap-2">
              <FilterInput
                value={subscribersFromFilterValue}
                placeholder={filters.from}
                IconComponent={RiUserLine}
                onValueChange={onSubscribersFromSearchChange}
                isNumber
              />
              <FilterInput
                value={subscribersToFilterValue}
                placeholder={filters.to}
                IconComponent={RiUserStarLine}
                onValueChange={onSubscribersToSearchChange}
                isNumber
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2 items-center justify-between flex-wrap relative z-0">
          <div className="flex flex-row gap-2 items-center justify-between">
            <Switch
              size={isMobile ? "sm" : "md"}
              isSelected={showArchivedLinks}
              onValueChange={onShowArchivedLinksChanges}
              classNames={{
                base: "inline-flex flex-row-reverse items-center",
              }}
            >
              <div className="text-xs font-medium mr-2">
                {buttons.showArchivedLinks}
              </div>
            </Switch>

            <Switch
              size={isMobile ? "sm" : "md"}
              isSelected={showAllOwnersLinks}
              onValueChange={onshowAllOwnersLinksChanges}
              classNames={{
                base: "inline-flex flex-row-reverse items-center",
              }}
            >
              <div className="text-xs font-medium mr-2">
                {buttons.showAllOwnersLinks}
              </div>
            </Switch>
          </div>

          <Button
            onClick={onClearFiltersClick}
            variant="flat"
            color="warning"
            size={isMobile ? "sm" : "md"}
          >
            {buttons.clearFilters}
          </Button>
        </div>
        { isTablet ? <SortingSelects sortDescriptor={sortDescriptor} setSortDescriptor={setSortDescriptor} /> : null }
      </div>
    );
  }, [
    titleFilterValue,
    dateFilterValue,
    tagsFilterValue,
    linkFilterValue,
    budgetFromFilterValue,
    budgetToFilterValue,
    subscribersFromFilterValue,
    subscribersToFilterValue,
    showArchivedLinks,
    showAllOwnersLinks,
    isMobile,
    isTablet,
    sortDescriptor,
  ]);
};

export default useTableTopContent;