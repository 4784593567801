import classNames from 'classnames'

import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import instance, { api } from '../../axios/config'

type ModalType = {
  openModal: boolean
  setOpenModal: any
  linkId: string
}

type setSubscriberType = {
  isValidSubscriber: boolean
  id: number
  created_at: string
  username: string
}

type setSubscribersType = setSubscriberType[] | []
const Modal: React.FC<ModalType> = ({ openModal, setOpenModal, linkId }) => {
  const [subscribers, setSubscribers] = useState<setSubscribersType>([])
  useEffect(() => {
    if (linkId) {
      const fetchSubscribers = async () => {
        try {
          const response = await instance.get(
            api.subscribers.replace(':id', linkId)
          )
          setSubscribers(response.data?.linkStatistics || [])
        } catch (error) {
          console.error('Error fetching subscribers:', error)
        }
      }
      fetchSubscribers()
    }
  }, [linkId])
  return (
    <div
      className={classNames(styles.bgModal, { [styles.showModal]: openModal })}
    >
      <div className={styles.wrap}>
        {
          <div className={styles.inner}>
            {subscribers.length ? (
              <div>
                <div className={styles.wrapTible}>
                  <div>Id</div>
                  <div>Created</div>
                  <div>Username</div>
                </div>
                {subscribers.map((value, index) => (
                  <div
                    key={index}
                    className={styles.wrapTible}
                    style={{ color: value.isValidSubscriber ? 'green' : 'red' }}
                  >
                    <div>{value.id}</div>
                    <div>{value.created_at}</div>
                    <div>{value.username}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.infoNan}>Информация отсутствует!</div>
            )}
          </div>
        }
        <div
          className={styles.closes}
          onClick={() => setOpenModal(false)}
        >
          <svg
            aria-hidden="true"
            fill="none"
            focusable="false"
            height="1em"
            role="presentation"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="1em"
          >
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Modal
