import axios from 'axios'
import { getApiUrl } from '../utils'

const instance = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,

  headers: {
    'Content-Type': 'application/json',
  },
})

export const api = {
  auth: '/auth/login',
  refresh: '/auth/refresh-tokens',
  users: '/users/:id',
  channels: '/channels',
  updateChannel: '/channels/:id',
  channelStatistics: '/channels/statistic',
  archiveChannel: '/channels/:id/archive',
  linksSearch: '/links/search',
  links: '/links',
  linkStatistics: '/links/:id/statistic',
  tags: '/tags/search',
  popularTags: '/tags/top-five',
  subscribers: '/links/:id/subscribers',
}

instance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem('access')
  request.headers.Authorization = accessToken
    ? `Bearer ${accessToken}`
    : undefined
  return request
})

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      const response = await instance.post(api.refresh)

      if (response.status === 200) {
        localStorage.setItem('access', response.data.access_token)

        return instance(error.config)
      }
    }

    return error
  }
)

export default instance
