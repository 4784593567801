import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link, useDisclosure } from "@nextui-org/react";
import { TEXTS } from "../../consts";
import LinkModal from "./components/Modals/LinkModal";
import { EditLinkType, LinkType } from "../../types";
import instance, { api } from "../../axios/config";
import ChannelButtons from "./components/ChannelButtons.tsx/ChannelButtons";
import ChannelLinksTableWrapper from "./components/ChannelLinksTable/ChannelLinksTableWrapper";
import { ChannelsContext } from "../../context/ChannelsContext";

const Channel: FunctionComponent = () => {
  const { channelTable: { title, addNewLinkModal } } = TEXTS;

  const [links, setLinks] = useState<LinkType[]>([]);
  const [linksCount, setLinksCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [budget, setBudget] = useState(0);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [pricePerSubscriber, setPricePerSubscriber] = useState(0);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { id } = useParams();
  const navigate = useNavigate();

  const { channels } = useContext(ChannelsContext);
  const { channelLink, channelName } = channels.find((channel) => channel.id === id) || {};

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const loadLinks = async () => {
    setIsLoading(true);
    const response = await instance.get(api.linksSearch, { params: { channelId: id, ownedByUser: true }});
    setIsLoading(false);
    const { status, data } = response;
    if (status === 200) {
      const { links, count, advBudget, subscribersCountTotal, pricePerSubscriber } = data;
      setLinks(links);
      setLinksCount(count);
      setBudget(advBudget);
      setSubscribersCount(subscribersCountTotal);
      setPricePerSubscriber(pricePerSubscriber);
    }
  };

  useEffect(() => {
    loadLinks();
  }, [id]);

  const addNewLink = async (data: EditLinkType) => {
    const response =  await instance.post(api.links, data);
    const { status } = response;
    if (status === 200 || status === 201) {
      loadLinks();
    }
  };

  if (!id) {
    return null;
  }

  const onSettingsClick = () => navigate(`/channels/${id}/settings`);

  return (
    <>
      <LinkModal
        title={addNewLinkModal.title}
        channelId={id}
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        onSubmit={addNewLink}
      />

      <Link href={channelLink} target="_blank" className="md:text-lg font-medium mb-2">
        {channelName}
      </Link>
      <div className="flex flex-row flex-wrap gap-2 justify-between items-start mb-2 md:mb-4">
        <div className="md:text-lg font-medium">
          {title}
        </div>
        <ChannelButtons onAddLinkClick={onOpen} onSettingsClick={onSettingsClick} />
      </div>
      <ChannelLinksTableWrapper
        channelId={id}
        links={links}
        initialLinksCount={linksCount}
        isInitialLoading={isLoading}
        initialBudget={budget}
        initialSubscribersCount={subscribersCount}
        initialPricePerSubscriber={pricePerSubscriber}
      />
    </>
  );
};

export default Channel;