import React, { FunctionComponent } from "react";
import { Input } from "@nextui-org/react";
import { IconType } from "react-icons";
import { NUMBER_REG_EXP } from "../../../../../consts";

type Props = {
  value: string;
  label?: string;
  placeholder: string;
  IconComponent: IconType;
  onValueChange: (value: string) => void;
  isNumber?: boolean;
};

const FilterInput: FunctionComponent<Props> = ({
  value,
  label,
  placeholder,
  IconComponent,
  onValueChange,
  isNumber,
}) => {
  const onClear = () => onValueChange("");

  const onChange = (value: string) => {
    if (!isNumber || NUMBER_REG_EXP.test(value)) {
      onValueChange(value);
    }
  };

  return (
    <Input
      label={label}
      labelPlacement="outside"
      isClearable
      classNames={{
        base: "w-full",
        inputWrapper: "border-1",
        input: "text-ellipsis"
      }}
      placeholder={placeholder}
      size="sm"
      startContent={<IconComponent size={18} />}
      value={value}
      variant="bordered"
      onClear={onClear}
      onValueChange={onChange}
    />
  )
};

export default FilterInput;