import React, { FunctionComponent } from "react";
import { NAVIGATION } from "../../../../../consts";
import NavbarLink from "./NavbarLink";
import ChannelsList from "./ChannelsList";

type Props = {
  onClickNavbarItem?: () => void;
};

const NavbarLinks: FunctionComponent<Props> = ({ onClickNavbarItem }) => {
  return (
    <div className="flex flex-col gap-y-2 lg:gap-y-6 w-full text-sm xl:text-base lg:mt-unit-20">
      {Object.entries(NAVIGATION).map(([key, value]) =>
          <div key={key}>
            <NavbarLink to={value.to} title={value.title} IconComponent={value.icon} onClick={onClickNavbarItem}/>
            {key === 'channels' && <ChannelsList onClick={onClickNavbarItem} />}
          </div>
      )}
    </div>
  );
};

export default NavbarLinks;