import React, { FunctionComponent } from "react";
import { NavLink,} from "react-router-dom";
import { Tooltip } from "@nextui-org/react";
import { IconType } from "react-icons";
import useBreakPoints from "../../../../../hooks/useBreakPoints";

type Props = {
  to: string;
  title: string;
  IconComponent: IconType;
  onClick?: () => void;
};

const NavbarLink: FunctionComponent<Props> = ({ to, title, IconComponent, onClick }) => {
  const { isSmallDesktop } = useBreakPoints();

  return (
    <Tooltip
      closeDelay={0}
      content={title}
      placement="right"
      className="hidden lg:block xl:hidden"
    >
      <NavLink to={to} onClick={onClick} className="outline-0">
        {({ isActive }) => (
          <div className={`flex flex-row items-center ${isActive ? "" : "hover:opacity-80"}`}>
            <div className={`mr-3 bg-background-200 rounded-lg p-2 ${isActive ? "bg-primary-100 text-primary-300" : ""} `}>
              <IconComponent size={isSmallDesktop ? 20 : 24} />
            </div>
            <span className={`lg:hidden xl:block ${isActive ? "font-medium" : ""}`}>{title}</span>
          </div>
        )}
      </NavLink>
    </Tooltip>
  );
};

export default NavbarLink;