import { useMemo } from "react";
import { SortDescriptor } from "@nextui-org/react";
import { LinkType } from "../../../types";
import { sortByDate } from "../../../utils";

type Options = {
  items: LinkType[];
  sortDescriptor: SortDescriptor;
};

const useSortLinks = ({items, sortDescriptor}: Options) => {
  return useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof LinkType];
      const second = b[sortDescriptor.column as keyof LinkType];
      let result;
        if (sortDescriptor.column === 'publishDate') {
          result = sortByDate(a, b);
        } else {
          result = (first || '') < (second || '') ? -1 : 1;
        }

        if (sortDescriptor.direction === "descending") {
          result = - result;
        }
        return result;
    });
  }, [sortDescriptor, items])
};

export default useSortLinks;