import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
} from '@nextui-org/react'
import { MultiValue } from 'react-select'
import DatePicker from 'react-datepicker'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { TEXTS, NUMBER_REG_EXP } from '../../../../consts'
import { EditLinkType, LinkType } from '../../../../types'
import instance, { api } from '../../../../axios/config'
import useBreakPoints from '../../../../hooks/useBreakPoints'

type Props = {
  title: string
  channelId: string
  link?: LinkType
  isOpen: boolean
  onOpenChange: () => void
  onClose: () => void
  onSubmit: (data: EditLinkType, id: string) => void
}

const LinkModal: FunctionComponent<Props> = ({
  title,
  channelId,
  link,
  isOpen,
  onOpenChange,
  onClose,
  onSubmit,
}) => {
  const { isSmallMobile } = useBreakPoints()

  const [name, setName] = useState('')
  const [placementChannel, setPlacementChannel] = useState('')
  const [cost, setCost] = useState('')
  const [tags, setTags] = useState<string[]>([])
  const [publishDate, setPublishDate] = useState<Date>()

  const [isNameInvalid, setIsNameInvalid] = useState(false)
  const [isCostInvalid, setIsCostInvalid] = useState(false)
  const [isDateInvalid, setIsDateInvalid] = useState(false)
  const [topOptions, setTopOptions] = useState<any>([])

  const [applications, setApplications] = useState(false)

  const {
    channelTable: { addNewLinkModal },
  } = TEXTS

  useEffect(() => {
    if (link) {
      setName(link.name)
      setPlacementChannel(link.placementChannel || '')
      setCost(link.cost?.toString())
      setPublishDate(new Date(link.publishDate))
      setApplications(link.hasApplicationMembership)
    }
  }, [link])

  const onNameChange = (value: string) => {
    setIsNameInvalid(false)
    setName(value)
  }

  const onPlacementChannelChange = (value: string) => {
    setPlacementChannel(value)
  }

  const onCostChange = (value: string) => {
    setIsCostInvalid(false)
    if (NUMBER_REG_EXP.test(value)) {
      setCost(value)
    }
  }

  const onPublishDateChange = (date: Date) => {
    setIsDateInvalid(false)
    setPublishDate(date)
  }

  const onApplicationsChange = () => setApplications((prevState) => !prevState)

  const onSubmitClick = () => {
    if (!name.trim()) {
      setIsNameInvalid(true)
      return
    }

    if (!Number(cost)) {
      setIsCostInvalid(true)
      return
    }

    if (!isNameInvalid && !isCostInvalid && !isDateInvalid) {
      const newTagNames: string[] = []
      const tagIds: string[] = []

      tags.forEach((tag: any) =>
        tag['__isNew__'] ? newTagNames.push(tag.label) : tagIds.push(tag.value)
      )

      onSubmit(
        {
          channelId,
          name,
          newTagNames,
          tagIds,
          hasApplicationMembership: applications.toString(),
          cost: Number(cost),
          publishDate: publishDate?.toISOString() || '',
          placementChannel,
        },
        link?.id || ''
      )
      clearInputs()
      onClose()
    }
  }

  const loadOptions = async (value: string) => {
    const response = await instance.get(api.tags, { params: { name: value } })
    const { data, status } = response
    if (status === 200) {
      return data.map((item: Record<string, string>) => ({
        value: item.id,
        label: item.name,
      }))
    }

    return []
  }

  const onTagsChange = (value: MultiValue<string>) => {
    setTags([...value])
  }

  const clearInputs = () => {
    setName(link ? link.name : '')
    setPlacementChannel(link ? link.placementChannel : '')
    setCost(link ? link.cost?.toString() : '')
    setPublishDate(link ? new Date(link.publishDate) : undefined)

    setApplications(false)
    setIsNameInvalid(false)
    setIsCostInvalid(false)
    setIsDateInvalid(false)
  }

  const openChangeHandler = () => {
    clearInputs()
    onOpenChange()
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await instance.get(api.popularTags)
        const { data, status } = await result
        if (status === 200) {
          setTopOptions(
            data.map((item: Record<string, string>) => ({
              value: item.id,
              label: item.name,
            }))
          )
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={openChangeHandler}
      scrollBehavior="outside"
      size="xl"
      classNames={{
        body: 'md:mb-5',
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
        <ModalBody>
          <Input
            label={addNewLinkModal.name.label}
            labelPlacement="outside"
            classNames={{
              label: 'text-base',
              base: 'mb-2',
              input: 'text-xs sm:text-base',
            }}
            placeholder={addNewLinkModal.name.placeholder}
            value={name}
            variant="bordered"
            isInvalid={isNameInvalid}
            onValueChange={onNameChange}
          />
          <Input
            label={addNewLinkModal.placementChannel.label}
            labelPlacement="outside"
            classNames={{
              label: 'text-base',
              base: 'mb-2',
              input: 'text-xs sm:text-base',
            }}
            placeholder={addNewLinkModal.placementChannel.placeholder}
            value={placementChannel}
            variant="bordered"
            onValueChange={onPlacementChannelChange}
          />
          <Input
            label={addNewLinkModal.cost.label}
            labelPlacement="outside"
            classNames={{
              label: 'text-base',
              base: 'mb-2',
              input: 'text-xs sm:text-base',
            }}
            placeholder={addNewLinkModal.cost.placeholder}
            value={cost}
            variant="bordered"
            isInvalid={isCostInvalid}
            onValueChange={onCostChange}
          />
          <div className="flex flex-col w-full mb-2">
            <label
              className={`font-medium text-base mb-1.5 ${
                isDateInvalid ? 'text-danger' : ''
              }`}
              htmlFor="date-searcher"
            >
              {addNewLinkModal.date.label}
            </label>
            <DatePicker
              id="date-searcher"
              dateFormat="dd.MM.yyyy"
              selected={publishDate}
              onChange={onPublishDateChange}
              placeholderText={addNewLinkModal.date.placeholder}
              showPopperArrow={false}
              customInput={
                <Input
                  classNames={{
                    base: 'w-full',
                    inputWrapper: 'border-2',
                    input: 'text-xs sm:text-base',
                  }}
                  variant="bordered"
                  isInvalid={isDateInvalid}
                />
              }
            />
          </div>
          <div className="flex flex-col w-full mb-4">
            <label
              className="font-medium text-base mb-1.5"
              htmlFor="date-searcher"
            >
              {addNewLinkModal.tags.label}
            </label>
            <AsyncCreatableSelect
              defaultOptions={topOptions}
              placeholder={addNewLinkModal.tags.placeholder}
              isClearable={false}
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              noOptionsMessage={() => (
                <p>По вашему запросу ничего не найдено</p>
              )}
              formatCreateLabel={(inputValue) => <p>Создать: "{inputValue}"</p>}
              loadingMessage={() => <p>Загрузка...</p>}
              onChange={onTagsChange}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  cursor: 'text',
                  borderWidth: '2px',
                  borderColor: state.isFocused
                    ? '#737373!important'
                    : '#e5e7eb',
                  boxShadow: 'none',
                  borderRadius: '12px',
                  height: '40px',
                  fontSize: isSmallMobile ? '12px' : '16px',
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused ? '#E9E9E9' : 'inherit',
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  zIndex: '100000',
                  fontSize: isSmallMobile ? '12px' : '16px',
                }),
              }}
            />
          </div>
          <Switch
            isSelected={applications}
            onValueChange={onApplicationsChange}
            classNames={{
              base: 'mt-3 flex flex-row-reverse justify-between max-w-full',
            }}
          >
            <span className="font-medium">{addNewLinkModal.applications}</span>
          </Switch>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={onSubmitClick}
          >
            {addNewLinkModal.submit}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LinkModal
