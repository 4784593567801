import React, { FunctionComponent } from "react";
import logoImg from "../icons/Logo.png";

type Props = {
  imageClassNames?: string
};

const Logo: FunctionComponent<Props> = ({
  imageClassNames = "",
}) => {
  return (
    <div className="flex justify-center relative">
      <img src={logoImg} alt="logo" className={imageClassNames} />
    </div>
  );
};

export default Logo;