import { BiHome, BiBox } from 'react-icons/bi'
import { LiaTelegramPlane } from 'react-icons/lia'
import { CgAddR } from 'react-icons/cg'

export const MAXIMUM_COST = 15
export const NUMBER_REG_EXP = /^\d*(?:[.,]\d*)?$/

export const PAGE_SELECT = {
  '50': 50,
  '100': 100,
  '200': 200,
  all: 'Все',
}

export const TEXTS = {
  logo: 'TG-Admin',
  authPage: {
    title: {
      start: 'Пригласительные ссылки для ваших ',
      end: 'телеграм-каналов',
    },
    subtitle:
      'Отслеживайте откуда к вам приходят подписчики при проведении рекламных кампаний',
    form: {
      title: 'Войти',
      button: 'Войти через телeграмм',
      link: 'Скопировать ссылку для входа',
      tooltip: 'Скопировать',
    },
  },
  channelTable: {
    title: 'Рекламные кампании',
    filters: {
      date: {
        label: 'Поиск по дате',
        placeholder: 'Выберите дату',
      },
      title: {
        label: 'Поиск по имени ссылки',
        placeholder: 'Введите имя ссылки',
      },
      tags: {
        label: 'Поиск по тэгу',
        placeholder: 'Введите название тэга',
      },
      link: {
        label: 'Поиск по ссылке',
        placeholder: 'Введите ссылку',
      },
      budget: {
        label: 'Поиск по бюджету',
      },
      subscribers: {
        label: 'Поиск по цене подписчика',
      },
      from: 'От',
      to: 'До',
    },
    buttons: {
      showArchivedLinks: 'Показать архивные ссылки',
      showAllOwnersLinks: 'Показать все ссылки этого канала',
      clearFilters: 'Очистить фильтры',
      export: 'Экспорт',
      settings: 'Настройки канала',
      addLink: 'Добавить ссылку',
    },
    noData: 'В этом канале пока нет ссылок',
    noFilteredData: 'По вашему запросу ничего не найдено',
    linksPerPage: 'Ссылок на странице: ',
    actions: {
      update: 'Обновить',
      edit: 'Редактировать',
      archive: 'Архировать',
      unarchive: 'Вернуть из архива',
      statistics: 'Статистика',
      remove: 'Удалить',
    },
    addNewLinkModal: {
      title: 'Добавление ссылки',
      name: {
        label: 'Имя ссылки',
        placeholder: 'Введите имя ссылки',
      },
      placementChannel: {
        label: 'Канал размещения',
        placeholder: 'Введите канал размещения',
      },
      cost: {
        label: 'Стоимость',
        placeholder: 'Введите стоимость ссылки',
      },
      tags: {
        label: 'Теги',
        placeholder: 'Введите новый тег или выберите из списка',
        placeholderTwo: 'Введите новый тег',
      },
      date: {
        label: 'Дата выхода',
        placeholder: 'Выберите дату выхода',
      },
      applications: 'Заявки на вступление',
      submit: 'Отправить',
    },
    editLinkModal: 'Редактирование ссылки',
    statistics: {
      title: 'Количество подписчиков по ',
      hour: 'часам',
      day: 'дням',
      subscribers: 'Подписчиков ',
      budget: 'Бюджет РК ',
      pricePerSubscriber: 'Цена подписчика ',
    },
    copyTooltip: {
      copy: 'Нажмите чтобы скопировать',
      copied: 'Скопировано',
    },
    deleteChannelModal: {
      title: 'Вы точно хотите удалить канал?',
      submit: 'Удалить',
      cancel: 'Отмена',
    },
  },
  main: {
    subscribers: 'Подписчиков',
    advertisementsCount: 'Запущено РК',
    budget: 'Бюджет РК',
    subscriberCost: 'Цена подписчика',
  },
  addChannel: {
    instructionsSection: {
      title: 'Инструкция по добавлению нового канала',
      description: 'Для добавление нового канала нужно воспользоваться ботом ',
      botTitle: 'TG Admin bot',
      botUsername: '@tg_admin_app_bot',
      botLink: 'https://t.me/tg_admin_app_bot',
      stepOne: 'Запустите телеграм-бота ',
      stepTwo: 'Нажмите кнопку "Добавить новый канал".',
      stepTree: 'Следуйте инструкциям, которые пришлет бот.',
      hint: 'Если возникнут сложности — можете посмотреть пошаговую видео-инструкцию на этой странице',
    },
    videoSection: 'Видео-инструкция по добавлению нового канала',
  },
  settings: {
    title: 'Настройки доступа',
    shareAccess: 'Поделиться доступом',
    archiveChannel: 'Перенести в архив',
    deleteChannel: 'Удалить канал',
    users: 'Пользователи канала',
    addNewUser: 'Добавить нового пользователя',
    rights: 'Права',
    modal: {
      title: 'Добавление нового пользователя',
      id: {
        label: 'ID пользователя',
        placeholder: 'Введите ID нового пользователя канала',
      },
      role: {
        title: 'Выберите права пользователя',
        watch: 'видеть чужие ссылки',
        manageOthersLinks: 'управление чужими ссылками',
        manageOwnLinks: 'управление своими ссылками',
      },
      button: 'Добавить нового пользователя',
    },
  },
  archivedChannels: {
    unarchive: 'Вернуть из архива',
    remove: 'Удалить канал',
  },
  noChannels: 'У вас пока нет каналов',
  noArchivedChannels: 'Архив каналов пуст',
  logOut: 'Выйти',
}

export const NAVIGATION = {
  main: {
    title: 'Главная',
    to: '/main',
    icon: BiHome,
  },
  channels: {
    title: 'Каналы',
    to: '/channels',
    icon: LiaTelegramPlane,
  },
  'add-channel': {
    title: 'Добавить канал',
    to: '/add-channel',
    icon: CgAddR,
  },
  'archive-channels': {
    title: 'Архив каналов',
    to: '/archive-channels',
    icon: BiBox,
  },
}

export const TABLE_COLUMNS = [
  {
    key: 'placementChannel',
    title: 'Канал размещения',
  },
  {
    key: 'publishDate',
    title: 'Дата выхода РК',
  },
  {
    key: 'name',
    title: 'Имя ссылки',
  },
  {
    key: 'tags',
    title: 'Теги',
  },
  {
    key: 'link',
    title: 'Ссылка',
  },
  {
    key: 'cost',
    title: 'Бюджет РК',
  },
  {
    key: 'subscribersCount',
    title: 'Подписчики',
  },

  {
    key: 'pricePerSubscriber',
    title: ' CPA(цена подписчика)',
  },
  {
    key: 'postCoverage',
    title: 'Охват поста',
  },
  {
    key: 'cpm',
    title: 'CPM',
  },
  {
    key: 'er',
    title: 'ER',
  },

  {
    key: 'hasApplicationMembership',
    title: 'Тип',
  },
  {
    key: 'actions',
    title: 'Действия',
  },
]

export const ARCHIVED_CHANNELS_TABLE_COLUMNS = [
  {
    key: 'channelName',
    title: 'Канал',
  },
  {
    key: 'createdAt',
    title: 'Дата создания',
  },
  {
    key: 'actions',
    title: 'Действия',
  },
]

export const SORTING_SELECT_OPTIONS = [
  {
    value: 'publishDate',
    label: 'Дата выхода РК',
  },
  {
    value: 'name',
    label: 'Имя ссылки',
  },
  {
    value: 'tags',
    label: 'Теги',
  },
  {
    value: 'link',
    label: 'Ссылка',
  },
  {
    value: 'subscribersCount',
    label: 'Подписчики',
  },
  {
    value: 'cost',
    label: 'Бюджет РК',
  },
  {
    value: 'pricePerSubscriber',
    label: 'Цена подписчика',
  },
]

export const SORTING_DIRECTION_SELECT_OPTIONS = [
  {
    value: 'ascending',
    label: 'От меньшего',
  },
  {
    value: 'descending',
    label: 'От большего',
  },
]
