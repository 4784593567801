import React, { ChangeEvent, FunctionComponent } from "react";
import { Select, SelectItem, SortDescriptor } from "@nextui-org/react";

import { SORTING_DIRECTION_SELECT_OPTIONS, SORTING_SELECT_OPTIONS } from "../../../../../consts";
import { SortDirection } from "../../../../../types";

type Props = {
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (sortDescriptor: SortDescriptor) => void;
};

const SortingSelects: FunctionComponent<Props> = ({ sortDescriptor, setSortDescriptor }) => {
  const oColumnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortDescriptor({ column: event.target.value, direction: sortDescriptor.direction });
  };

  const onDirectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortDescriptor({ direction: event.target.value as SortDirection, column: sortDescriptor.column });
  };

  return (
    <div className="w-full mt-2 grid grid-cols-5 gap-2">
      <Select
        fullWidth={true}
        disableSelectorIconRotation={false}
        color="default"
        isMultiline={false}
        radius="sm"
        labelPlacement="inside"
        disableAnimation={false}
        label="Сортировать по полю:"
        defaultSelectedKeys={[sortDescriptor.column as string]}
        size="sm"
        variant="bordered"
        classNames={{
          trigger: "border-1 h-unit-10",
          base: "col-span-3",
        }}
        onChange={oColumnChange}
        disallowEmptySelection
      >
        {SORTING_SELECT_OPTIONS.map(({label, value}) => (
          <SelectItem key={value} value={value}>
            {label}
          </SelectItem>
        ))}
      </Select>

      <Select
        fullWidth={true}
        disableSelectorIconRotation={false}
        color="default"
        isMultiline={false}
        radius="sm"
        labelPlacement="inside"
        disableAnimation={false}
        label="Направление"
        defaultSelectedKeys={[sortDescriptor.direction as string]}
        size="sm"
        variant="bordered"
        onChange={onDirectionChange}
        classNames={{
          trigger: "border-1 h-unit-10",
          base: "col-span-2",
        }}
        disallowEmptySelection
      >
        {SORTING_DIRECTION_SELECT_OPTIONS.map(({label, value}) => (
          <SelectItem key={value} value={value}>
            {label}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default SortingSelects;