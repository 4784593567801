import React, { useState, useEffect, useRef, FunctionComponent } from "react";
import { DateRange as DateRangeComponent, Range, RangeKeyDict } from "react-date-range";
import formatt from "date-fns/format";
import { Input } from "@nextui-org/react";
import { BiCalendar } from "react-icons/bi";
import { DateFilterValue } from "../../types";
import styles from "./DateRange.module.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

type Props = {
  dateFilterValue: DateFilterValue;
  onDateSearchChange: (value: DateFilterValue) => void;
};

const DateRange: FunctionComponent<Props> = ({ dateFilterValue, onDateSearchChange }) => {
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const rangeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape,true);
    document.addEventListener("click", hideOnClickOutside,true);

    return () => {
      document.removeEventListener("keydown", hideOnEscape);
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!dateFilterValue.startDate && !dateFilterValue.endDate) {
      setRange([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }]);
      setInputValue("");
    }
  }, [dateFilterValue]);

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: Event) => {
    if (rangeRef.current && !rangeRef.current.contains(e.target as HTMLElement)) {
      setOpen(false);
    }
  };

  const onInputClick = () => setOpen(open => !open);
  const onClear = () => {
    onDateSearchChange({
      startDate: undefined,
      endDate: undefined,
    });
  };

  const onChange = (rangesByKey: RangeKeyDict) => {
    setRange([rangesByKey.selection])
    onDateSearchChange({ startDate: rangesByKey.selection.startDate, endDate: rangesByKey.selection.endDate });
    setInputValue(
      `${formatt(rangesByKey.selection.startDate || 0, "dd.MM.yyyy")} - ${formatt(rangesByKey.selection.endDate || 0, "dd.MM.yyyy")}`
    );
  };

  return (
    <div>
      <Input
        label={"Поиск по дате"}
        labelPlacement="outside"
        placeholder={"Выберите диапазон"}
        startContent={<BiCalendar size={18}  />}
        value={inputValue}
        variant="bordered"
        isClearable
        onClick={onInputClick}
        onClear={onClear}
        size="sm"
        classNames={{
          base: "w-full",
          inputWrapper: "border-1",
          input: "text-ellipsis",
        }}
      />

      <div ref={rangeRef}>
        { open
          ? <DateRangeComponent
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="horizontal"
              onChange={onChange}
              className={styles.calendar}
              maxDate={new Date()}
            />
          : null
        }
      </div>
    </div>
  );
};

export default DateRange;