import React, { FunctionComponent, useContext } from "react";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { ARCHIVED_CHANNELS_TABLE_COLUMNS, TEXTS } from "../../consts";
import { ArchivedTableColumnKey } from "../../types";
import { ChannelsContext } from "../../context/ChannelsContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import useRenderCell from "./hooks/useRenderCell";
import instance, { api } from "../../axios/config";

const ArchivedChannels: FunctionComponent = () => {
  const { noArchivedChannels } = TEXTS;
  const { channels, isLoading, loadChannels } = useContext(ChannelsContext);

  const archivedChannels = channels.filter(({ isArchived }) => isArchived);

  const handleClickUnarchive = async (channelId: string) => {
    const response = await instance.post(api.archiveChannel.replace(':id', channelId));
    const { status } = response;
    if (status === 201) {
      loadChannels();
    }
  };

  const renderCell = useRenderCell({ handleClickUnarchive });

  if (isLoading) {
    return (<LoadingSpinner />)
  }

  if (!archivedChannels.length) {
    return (
      <div>
        {noArchivedChannels}
      </div>
    );
  }

  return (
    <Table aria-label="Archived channels">
      <TableHeader>
        {ARCHIVED_CHANNELS_TABLE_COLUMNS.map(({ key, title }) =>
          <TableColumn key={key} align={"center"}>
            {title}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody>
        {archivedChannels.map(channel =>
          <TableRow key={channel.id}>
            {ARCHIVED_CHANNELS_TABLE_COLUMNS.map(({ key }) =>
              <TableCell key={key}>{renderCell(channel, key as ArchivedTableColumnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default ArchivedChannels;