import React, { FunctionComponent, useContext } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { TEXTS } from "../../consts";
import { ChannelsContext } from "../../context/ChannelsContext";
import LoadingSpinner from "../../components/LoadingSpinner";

const Channels: FunctionComponent = () => {
  const { channels, isLoading } = useContext(ChannelsContext);
  const { id } = useParams();
  const { noChannels } = TEXTS;

  const activeChannels = channels.filter(({ isArchived }) => !isArchived);

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  if (!activeChannels.length) {
    return (
      <div>
        {noChannels}
      </div>
    );
  }

  const firstChannelId = activeChannels[0].id;

  if (id) {
    return <Outlet />
  }

  return <Navigate to={`/channels/${firstChannelId}`}/>
}

export default Channels;
