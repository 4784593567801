import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  Link
} from "@nextui-org/react";
import { TEXTS } from "../../../../../consts";
import { LinkType, StatisticsData, StatisticsDataType } from "../../../../../types";
import instance, { api } from "../../../../../axios/config";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import DayChart from "./DayChart";
import HourChart from "./HourChart";

type Props = {
  link?: LinkType;
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
};

const StatisticsModal: FunctionComponent<Props> = ({ link, isOpen, onOpenChange }) => {
  const [data, setData] = useState<StatisticsData>({ items: [], type: StatisticsDataType.HOUR });
  const [isLoading, setLoading] = useState(false);

  const { channelTable: { statistics } } = TEXTS;

  useEffect(() => {
    if (link) {
      (async function () {
        setLoading(true);
        const response = await instance.get(api.linkStatistics.replace(':id', link.id));
        setLoading(false);
        const { status, data } = response;
        if (status === 200) {
          setData(data);
        }
      })();
    }
  }, [link]);

  if (!link) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      classNames={{
        base: "max-w-full md:max-w-[80vw]",
        body: "md:mb-5"
      }}
    >
      <ModalContent>
        {
          isLoading
            ? <LoadingSpinner />
            : <ModalBody className="px-3 md:px-6">
            <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 text-sm md:text-base">
              <div className="col-span-2 sm:col-span-3 lg:col-span-2">
                <Link href={link.link || ''} target="_blank">{link.link}</Link>
              </div>
              <div>
                <span className="font-medium">{statistics.subscribers}</span>
                {link.subscribersCount}
              </div>
              <div>
                <span className="font-medium">{statistics.budget}</span>
                {link.cost}
              </div>
              <div>
                <span className="font-medium">{statistics.pricePerSubscriber}</span>
                {link.pricePerSubscriber}
              </div>
            </div>
            <Divider/>
            <div className="md:mt-5 text-base md:text-lg">
              {statistics.title}
              { data.type === StatisticsDataType.HOUR ? statistics.hour : statistics.day }
            </div>
              { data.type === StatisticsDataType.HOUR ? <HourChart data={data.items} /> : <DayChart data={data.items} /> }
          </ModalBody>
        }
      </ModalContent>
    </Modal>
  );
};

export default StatisticsModal;