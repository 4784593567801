import React, { useCallback } from "react";
import { Tooltip } from "@nextui-org/react";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { TEXTS } from "../../../consts";
import { ArchivedTableColumnKey, ChannelType } from "../../../types";

type Options = {
  handleClickUnarchive: (id: string) => void;
};

const useRenderCell = ({ handleClickUnarchive }: Options) => {
  const { archivedChannels: { unarchive }} = TEXTS;

  const onClickUnarchive = (id: string) => {
    return () => handleClickUnarchive(id);
  };

  return useCallback((channel: ChannelType, columnKey: ArchivedTableColumnKey) => {
    switch (columnKey) {
      case "createdAt":
        return new Date(channel[columnKey]).toLocaleDateString();
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip closeDelay={0} content={unarchive}>
              <span className="text-lg cursor-pointer active:opacity-50" onClick={onClickUnarchive(channel.id)}>
                <RiInboxUnarchiveLine size={20} />
              </span>
            </Tooltip>
          </div>
        )
    }

    return channel[columnKey];
  }, []);
};

export default useRenderCell;