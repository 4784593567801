import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import TabletNavbar from "./TabletNavbar";
import useBreakPoints from "../../../../hooks/useBreakPoints";
import Logo from "../../../../components/Logo";
import NavbarLinks from "./components/NavbarLinks";
import LogOutButton from "./components/LogOutButton";

type Props = {
  logOut: () => void;
};

const Navbar: FunctionComponent<Props> = ({ logOut }) => {
  const { isTablet } = useBreakPoints();


  if (isTablet) {
    return <TabletNavbar logOut={logOut}/>
  }

  return (
    <nav className="flex flex-col justify-between pl-4 py-7 pr-2 absolute min-h-screen w-[65px] xl:w-[210px] lg:border-r-2 lg:border-white">
      <Link to='/main' className='hover:opacity-80'>
        <Logo imageClassNames="relative -left-1 max-w-[50px] xl:max-w-[150px]" />
      </Link>
      <NavbarLinks />
      <LogOutButton onLogOutClick={logOut} />
    </nav>
  );
};

export default Navbar;