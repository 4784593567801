import React, {FunctionComponent, useContext} from "react";
import { User } from "@nextui-org/react";
import { getInitials } from "../utils";
import { UserContext } from "../context/UserContext";

type Props = {
  className?: string;
};

const UserInfo: FunctionComponent<Props> = ({ className }) => {
  const { tgUsername, tgUserId, tgPhoto } = useContext(UserContext);

  return (
    <User
      name={tgUsername}
      description={`ID: ${tgUserId}`}
      avatarProps={{
        getInitials: getInitials,
        src: tgPhoto ? `data:image/jpeg;base64,${tgPhoto}` : '',
    }}
      classNames={{
        name: "font-medium",
      }}
      className={className}
    />
  );
};

export default UserInfo;