import React, { FunctionComponent, ReactNode } from 'react'
import { ColumnKey, LinkType } from '../../../../types'
import { Card, CardBody } from '@nextui-org/react'
import { TABLE_COLUMNS } from '../../../../consts'
import ScrollToTopButton from './components/ScrollToTopButton'

type Props = {
  links: LinkType[]
  renderCell: (item: LinkType, columnKey: ColumnKey) => ReactNode
  topContent: ReactNode
  bottomContent: ReactNode
  budget: number
  subscribersCount: number
  pricePerSubscriber: number
  handleTogleModal: any
}

const TabletChannelLinks: FunctionComponent<Props> = ({
  links,
  renderCell,
  topContent,
  bottomContent,
  budget,
  subscribersCount,
  pricePerSubscriber,
  handleTogleModal,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {topContent}
      <div className="grid md:grid-cols-2 gap-2">
        {links.map((link) => (
          <Card
            key={link.id}
            shadow="sm"
            className="w-full"
            classNames={{
              body: 'p-4',
            }}
          >
            <CardBody className="flex flex-col gap-2">
              {TABLE_COLUMNS.map(({ key, title }, index) => (
                <div
                  key={key}
                  className="flex flex-wrap justify-between items-center gap-1"
                  onClick={() =>
                    index === 6 ? handleTogleModal(true, link.id) : null
                  }
                >
                  <span className="text-xs font-medium">{title}</span>
                  <span className="text-sm">
                    {index === 8 || index === 9 || index === 10
                      ? {
                          8: !link.postCoverage,
                          9: !link.cpm,
                          10: !link.er,
                        }[index]
                        ? `Loading ${link.loadingPercent}%`
                        : renderCell(link, key as ColumnKey)
                      : renderCell(link, key as ColumnKey)}
                  </span>
                </div>
              ))}
            </CardBody>
          </Card>
        ))}
      </div>
      <Card
        classNames={{
          body: 'p-4',
        }}
      >
        <CardBody className="flex flex-row justify-between gap-2 text-xs">
          <div className="flex flex-row flex-wrap justify-center gap-2 gap-y-0">
            <div className="font-medium">Подписчики</div>
            <div>{subscribersCount}</div>
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-2 gap-y-0">
            <div className="font-medium">Бюджет PK</div>
            <div>{budget}</div>
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-2 gap-y-0">
            <div className="font-medium">Цена подписчика</div>
            <div>{pricePerSubscriber}</div>
          </div>
        </CardBody>
      </Card>
      {bottomContent}
      <ScrollToTopButton />
    </div>
  )
}

export default TabletChannelLinks
