import { useEffect } from "react";
import { EvenType, User } from "../types";
import { getApiUrl } from "../utils";

type Options = {
  loadChannels: () => void;
  user: User;
  isLoading: boolean;
};

const useEventSource = ({ loadChannels, user, isLoading }: Options) => {
  useEffect(() => {
    const eventSource = new EventSource(`${getApiUrl()}/sse`);

    eventSource.onmessage = (e: MessageEvent) => {
      const { eventType, tgUserId } = JSON.parse(e.data);
      const { id } = user;

      if (eventType === EvenType.ChannelAdded && id === tgUserId ) {
        loadChannels();
      }
    };

    return () => eventSource.close();
  }, [user, isLoading]);
};

export default useEventSource;