import React, { FunctionComponent } from "react";
import { Link } from "@nextui-org/react";
import { BiLogOut } from "react-icons/bi";
import { TEXTS } from "../../../../../consts";
import useBreakPoints from "../../../../../hooks/useBreakPoints";

type Props = {
  onLogOutClick: () => void;
};

const LogOutButton: FunctionComponent<Props> = ({ onLogOutClick }) => {
  const { logOut }  = TEXTS;
  const { isSmallDesktop, isTablet, isMobile } = useBreakPoints();

  return (
    <Link
      className="mt-10 ml-4 lg:ml-1 xl:ml-4 flex flex-row justify-start text-text-100 text-sm xl:text-base cursor-pointer hover:opacity-70 gap-2"
      onClick={onLogOutClick}
    >
      <BiLogOut size={isSmallDesktop ? 22 : 26}/>
      {isSmallDesktop && !isTablet && !isMobile ? '' : logOut}
    </Link>
  );
};

export default LogOutButton;