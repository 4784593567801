import React, { FunctionComponent, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Button,
  useDisclosure
} from "@nextui-org/react";
import { TEXTS } from "../../consts";
import AddNewUserModal from "./components/AddNewUserModal";
import { ChannelsContext } from "../../context/ChannelsContext";
import { Navigate, useParams } from "react-router-dom";
import ChannelActions from "./components/ChannelActions";
import useBreakPoints from "../../hooks/useBreakPoints";
import UserInfo from "../../components/UserInfo";

const Settings: FunctionComponent = () => {
  const { channels } = useContext(ChannelsContext);
  const { id: channelId } = useParams();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isMobile } = useBreakPoints();

  const buttonSize = isMobile ? "sm" : "md";

  const channel = channels.find(({ id }) => id === channelId);

  const { settings } = TEXTS;

  if (!channels.length || !channel || !channelId || channel.isArchived) {
    return <Navigate to={'/main'} />;
  }

  return (
    <>
      <div className="md:text-lg font-medium">
        {settings.title}
      </div>
      <div className="mt-5 grid grid-cols-2 lg:grid-cols-3 grid-rows-[340px 1fr] gap-3">
        <ChannelActions id={channelId} />
        <Card shadow="sm" className="w-full col-span-2 row-span-2">
          <CardHeader>
            <div className="w-full flex flex-row gap-1 flex-wrap justify-between items-center">
              <div className="text-sm md:text-base font-medium">
                {settings.users}
              </div>
              {/*<Button color="primary" className="pb-1" onClick={onOpen} size={buttonSize}>*/}
              {/*  {settings.addNewUser}*/}
              {/*</Button>*/}
            </div>
          </CardHeader>
          <Divider orientation="horizontal"/>
          <CardBody className="flex flex-row flex-wrap gap-x-5 gap-y-3">
            <UserInfo />
          </CardBody>
        </Card>
      </div>

      <AddNewUserModal isOpen={isOpen} onOpenChange={onOpenChange} onClose={onClose} />
    </>
  );
}

export default Settings;