import React, { FunctionComponent, useContext } from "react";
import { Avatar, Tooltip } from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import { getInitials } from "../../../../../utils";
import { ChannelsContext } from "../../../../../context/ChannelsContext";

type Props = {
  onClick?: () => void;
};

const ChannelsList: FunctionComponent<Props> = ({ onClick }) => {
  const { channels } = useContext(ChannelsContext);

  const activeChannels = channels.filter(({ isArchived }) => !isArchived);

  if (!activeChannels.length) {
    return null;
  }

  return (
    <div className="ml-2 lg:ml-0 xl:ml-2 flex flex-col">
      {activeChannels.map(({ id, channelName }) =>
        <Tooltip
          key={id}
          closeDelay={0}
          content={channelName}
          placement="right"
          className="hidden lg:block xl:hidden"
        >
        <NavLink to={'/channels/' + id} onClick={onClick} className="outline-0 mt-3">
          {({ isActive }) => (
            <div className={`flex flex-row items-center gap-2 rounded-xl ${isActive ? "bg-background-200" : "hover:opacity-70"} p-1`}>
              <Avatar
                name={channelName}
                getInitials={getInitials}
                size="sm"
              />
              <div className="lg:hidden xl:block lg:max-w-[100px] xl:max-w-[120px] break-words text-xs xl:text-sm">{channelName}</div>
            </div>
          )}
        </NavLink>
        </Tooltip>
      )}
    </div>
  );
};

export default ChannelsList;