import {useEffect, useState} from "react";
import {BREAK_POINTS} from "../types";

const useBreakPoints = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSmallMobile = windowWidth < BREAK_POINTS.SM;
  const isMobile = windowWidth < BREAK_POINTS.MD;
  const isTablet = windowWidth < BREAK_POINTS.LG;
  const isSmallDesktop = windowWidth < BREAK_POINTS.XL;

  return { isSmallMobile, isMobile, isTablet, isSmallDesktop };
};

export default useBreakPoints;