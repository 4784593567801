import React, { FunctionComponent } from "react";
import { Tooltip } from "@nextui-org/react";
import { LuPieChart } from "react-icons/lu";
import { BiTrash } from "react-icons/bi";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { BsArchive } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { TEXTS } from "../../../../../consts";

type Props = {
  id: string;
  isOwn: boolean;
  handleClickEdit: (id: string) => void;
  handleClickArchive: (id: string) => void;
  handleClickStatistics: (id: string) => void;
  handleClickDelete: (id: string) => void;
  showArchivedLinks: boolean;
};

const LinkActions: FunctionComponent<Props> = ({
  id,
  isOwn,
  showArchivedLinks,
  handleClickArchive,
  handleClickStatistics,
  handleClickDelete,
  handleClickEdit
}) => {
  const { channelTable: { actions } } = TEXTS;

  const onClickStatistics = (id: string) => {
    return () => handleClickStatistics(id);
  };

  const onClickDelete = (id: string) => {
    if (isOwn) {
      return () => handleClickDelete(id);
    }
    return () => {};
  };

  const onClickEdit = (id: string) => {
    if (isOwn) {
      return () => handleClickEdit(id);
    }
    return () => {};
  };

  const onClickArchive = (id: string) => {
    if (isOwn) {
      return () => handleClickArchive(id);
    }
    return () => {};
  };

  return (
    <div className="relative flex items-center gap-2">
      { showArchivedLinks
        ? <>
          <Tooltip closeDelay={0} content={actions.unarchive} className={isOwn ? "" : "bg-background-200"}>
            <span
              onClick={onClickArchive(id)}
              className={`text-lg text-primary-100 cursor-pointer active:opacity-40 ${isOwn ? "" : "opacity-40"}`}
            >
              <RiInboxUnarchiveLine />
            </span>
          </Tooltip>

          <Tooltip closeDelay={0} content={actions.remove} className={isOwn ? "" : "bg-background-200"}>
            <span
              onClick={onClickDelete(id)}
              className={`text-lg text-warning cursor-pointer active:opacity-40 ${isOwn ? "" : "opacity-40"}`}
            >
              <BiTrash />
            </span>
          </Tooltip>
        </>
        : <>
          <Tooltip closeDelay={0} content={actions.edit} className={isOwn ? "" : "bg-background-200"}>
            <span
              className={`text-lg cursor-pointer active:opacity-40 ${isOwn ? "" : "opacity-40"}`}
              onClick={onClickEdit(id)}
            >
              <FiEdit />
            </span>
          </Tooltip>

          <Tooltip closeDelay={0} content={actions.statistics}>
            <span
              onClick={onClickStatistics(id)}
              className="text-lg text-primary-100 cursor-pointer active:opacity-40"
            >
              <LuPieChart />
            </span>
          </Tooltip>

          <Tooltip closeDelay={0} content={actions.archive} className={isOwn ? "" : "bg-background-200"}>
            <span
              onClick={onClickArchive(id)}
              className={`text-lg text-warning-100 cursor-pointer active:opacity-40 ${isOwn ? "" : "opacity-40"}`}
            >
              <BsArchive />
            </span>
          </Tooltip>
        </>
      }
    </div>
  );
};

export default LinkActions;