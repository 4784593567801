import React, { FunctionComponent } from "react";
import { Button } from "@nextui-org/react";
import { TEXTS } from "../../../../consts";
import useBreakPoints from "../../../../hooks/useBreakPoints";

type Props = {
  onAddLinkClick: () => void;
  onSettingsClick: () => void;
};

const ChannelButtons: FunctionComponent<Props> = ({ onAddLinkClick, onSettingsClick }) => {
  const { channelTable: { buttons } } = TEXTS;

  const { isMobile } = useBreakPoints();
  const buttonSize = isMobile ? "sm" : "md";
  const buttonClassName = "pb-1 px-2 md:px-3";

  return (
    <>
      <div className="flex flex-wrap gap-2 md:gap-4 items-end">
        <Button className={buttonClassName} variant="faded" color="primary" radius="sm" size={buttonSize}>
          {buttons.export}
        </Button>
        <Button className={buttonClassName} variant="flat" color="primary" radius="sm" onClick={onSettingsClick} size={buttonSize}>
          {buttons.settings}
        </Button>
        <Button className={buttonClassName} color="primary" radius="sm" onClick={onAddLinkClick} size={buttonSize}>
          {buttons.addLink}
        </Button>
      </div>
    </>
  );
}

export default ChannelButtons;
