import React, { FunctionComponent } from "react";
import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react";
import { TEXTS } from "../../consts";
import AddChannelSteps from "./components/AddChannelSteps";

const AddChannel: FunctionComponent = () => {
  const { addChannel: { instructionsSection, videoSection } } = TEXTS;

  return (
    <div className="grid md:grid-cols-2 gap-3">
      <Card shadow="sm" className="w-full">
        <CardHeader className="font-medium">
          {instructionsSection.title}
        </CardHeader>
        <Divider orientation="horizontal"/>
        <CardBody className="flex flex-col gap-3">
          <AddChannelSteps />
        </CardBody>
      </Card>
      <Card shadow="sm" className="w-full">
        <CardHeader className="font-medium">
          {videoSection}
        </CardHeader>
        <Divider orientation="horizontal"/>
        <CardBody className="flex flex-col gap-2 min-h-[150px]">
        </CardBody>
      </Card>
    </div>
  );
}

export default AddChannel;
