import React, { FunctionComponent, useEffect, useState } from "react";
import ChannelLinksTable from "./ChannelLinksTable";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { LinkSearchParams, LinkType } from "../../../../types";
import instance, { api } from "../../../../axios/config";
import { TEXTS } from "../../../../consts";

type Props = {
  channelId: string;
  links: LinkType[];
  isInitialLoading: boolean;
  initialLinksCount: number;
  initialBudget: number;
  initialSubscribersCount: number;
  initialPricePerSubscriber: number;
};

const ChannelLinksTableWrapper: FunctionComponent<Props> = ({
  channelId,
  links,
  initialLinksCount,
  initialBudget,
  initialSubscribersCount,
  initialPricePerSubscriber,
  isInitialLoading
}) => {
  const { channelTable: { noData } } = TEXTS;

  const [isLoading, setIsLoading] = useState(false);
  const [filteredLinks, setFilteredLinks] = useState<LinkType[]>(links);
  const [linksCount, setLinksCount] = useState(0);
  const [budget, setBudget] = useState(0);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [pricePerSubscriber, setPricePerSubscriber] = useState(0);

  useEffect(() => {
    if (links.length) {
      setFilteredLinks(links);
      setLinksCount(initialLinksCount);
      setBudget(initialBudget);
      setSubscribersCount(initialSubscribersCount)
      setPricePerSubscriber(initialPricePerSubscriber);
    }
  }, [links]);

  const loadFilteredLinks = async (params: LinkSearchParams) => {
    setIsLoading(true);
    const response = await instance.get(api.linksSearch, { params: {
        ...params,
        channelId
      }
    });
    setIsLoading(false);
    const { status, data } = response;
    if (status === 200) {
      const { links, count, advBudget, subscribersCountTotal, pricePerSubscriber } = data;
      setFilteredLinks(links);
      setLinksCount(count);
      setBudget(advBudget);
      setSubscribersCount(subscribersCountTotal);
      setPricePerSubscriber(pricePerSubscriber);
    }
  };

  if (isInitialLoading) {
    return (<LoadingSpinner />);
  }

  if (!initialLinksCount) {
    return (
      <div>
        {noData}
      </div>
    );
  }

  return (
    <ChannelLinksTable
      id={channelId}
      isLoading={isLoading}
      links={filteredLinks}
      linksCount={linksCount}
      loadLinks={loadFilteredLinks}
      budget={budget}
      subscribersCount={subscribersCount}
      pricePerSubscriber={pricePerSubscriber}
    />
  );
};

export default ChannelLinksTableWrapper;