import React, { FunctionComponent } from "react";
import { Link } from "@nextui-org/react";
import { TEXTS } from "../../../consts";

const AddChannelSteps: FunctionComponent = () => {
  const { addChannel: { instructionsSection } } = TEXTS;

  const stepClassName = "inline-flex w-5 h-5 mr-2 justify-center items-center rounded-full bg-primary-100 text-white text-xs";

  return (
    <>
      <div>
        {instructionsSection.description}
        <Link target="_blank" href={instructionsSection.botLink}>{instructionsSection.botTitle}</Link>
      </div>
      <ul className="ml-3 flex flex-col gap-3">
        <li key="stepOne">
          <div className={stepClassName}>1</div>
          {instructionsSection.stepOne}
          <Link target="_blank" href={instructionsSection.botLink}>{instructionsSection.botUsername}</Link>
        </li>
        <li key="stepTwo">
          <div className={stepClassName}>2</div>
          {instructionsSection.stepTwo}
        </li>
        <li key="stepTree">
          <div className={stepClassName}>3</div>
          {instructionsSection.stepTree}
        </li>
      </ul>
      <div>
        {instructionsSection.hint}
      </div>
    </>
  );
}

export default AddChannelSteps;