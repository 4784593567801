import { NAVIGATION, PAGE_SELECT } from './consts'

export type TelegramLoginButtonProps = {
  botName: string
  dataOnAuth?: (user: TelegramUser) => void
  dataAuthUrl?: string
  buttonSize?: 'large' | 'medium' | 'small'
  cornerRadius?: number
  requestAccess?: boolean
  usePic?: boolean
  lang?: string
}

// DON'T CHANGE NEITHER NUMBER OF PROPERTIES NOR PROPERTIES NAMES
export type TelegramUser = {
  id: string
  username: string
  first_name: string
  last_name: string
  photo_url: string
  auth_date: string
  hash: string
}

export type ChannelType = {
  id: string
  channelId: string
  createdAt: string
  channelName: string
  isActive: boolean
  channelLink: string
  isArchived: boolean
  userId: string
}

export type LinkType = {
  id: string
  createdAt: string
  name: string
  memberLimit: number | null
  expireDate: string | null
  link: string | null
  tags: Tag[]
  isRevoked: boolean
  isArchived: boolean
  cost: number
  publishDate: string
  channelId: string
  subscribersCount: number
  pricePerSubscriber: number
  ownerUserId: string
  placementChannel: string
  hasApplicationMembership: boolean
  loadingPercent: number
  cpa: number | null
  cpm: number | null
  er: number | null
  postCoverage: number | null
}

export type Tag = {
  id: string
  name: string
  createdAt: string
}

export type EditLinkType = {
  channelId: string
  name: string
  cost: number
  publishDate: string
  tagIds: string[]
  newTagNames: string[]
  hasApplicationMembership: string
  placementChannel: string
}

export type User = {
  id: string
  createdAt: string
  tgUserId: string
  tgUsername: string
  tgUserLink: string
  tgPhotoUrl: string
  tgPhoto: string
  isEnabled: boolean
}

export type DecodedAccessToken = {
  exp: string
  iat: string
  sub: string
  tgId: string
  username: string
}

export type IconProps = {
  width?: number
  height?: number
  fillColor?: string
  className?: string
}

export type ColumnKey =
  | 'placementChannel'
  | 'publishDate'
  | 'name'
  | 'tags'
  | 'link'
  | 'subscribersCount'
  | 'cost'
  | 'pricePerSubscriber'
  | 'hasApplicationMembership'
  | 'actions'
  | 'cpa'
  | 'cpm'
  | 'er'
  | 'postCoverage'

export type ArchivedTableColumnKey = 'channelName' | 'createdAt' | 'actions'

export type Navigation = typeof NAVIGATION

export type DateFilterValue = {
  startDate: Date | undefined
  endDate: Date | undefined
}

export type LinkSearchParams = {
  startDate?: string
  endDate?: string
  linkName?: string
  tagName?: string
  linkValue?: string
  budgetFrom?: string
  budgetTo?: string
  pricePerSubscriberFrom?: string
  pricePerSubscriberTo?: string
  isArchived?: boolean
  ownedByUser?: boolean
  take?: PageSize
  skip?: number
}

export type StatisticsData = {
  items: [number, number][]
  type: StatisticsDataType
}

export enum StatisticsDataType {
  DAY = 'day',
  HOUR = 'hour',
}

export type ChannelStatistics = {
  channelId: string
  advBudget: number
  advCampaignPriceTotal: number
  pricePerSubscriber: number
  subscribersCountTotal: number
}

export type PageSize = keyof typeof PAGE_SELECT

export enum BREAK_POINTS {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
}

export type SortDirection = 'ascending' | 'descending'

export enum EvenType {
  ChannelAdded = 'channelAdded',
  LinkAdded = 'linkAdded',
}
