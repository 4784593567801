export const InfoEnabled = () => {
  return (
    <div className="h-screen min-h-[450px]">
      <div className="px-4 mx-auto max-w-lg md:max-w-full flex flex-col items-center justify-center md:center md:py-unit-10 gap-6 h-full pb-unit-10 z-1 relative">
        <div className="text-center">
          <div className="mb-unit-6 sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl font-medium">
            Почти готово! Здравствуйте, сейчас Ваш аккаунт находится на
            верификации у администраторов. В Ближайшее время Вам будет
            предоставлен доступ, либо, с вами свяжется менеджер для уточнения
            данных.
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoEnabled
