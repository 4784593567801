import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import AddChannel from '../addChannel/AddChannel'
import instance, { api } from '../../axios/config'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ChannelsContext } from '../../context/ChannelsContext'
import ChannelStatisticsCard from './components/ChannelStatisticsCard'
import { ChannelStatistics } from '../../types'
import { useNavigate } from 'react-router-dom'

const Main: FunctionComponent = () => {
  const navigate = useNavigate()
  const { channels, isLoading } = useContext(ChannelsContext)
  const activeChannels = channels.filter(({ isArchived }) => !isArchived)

  const [channelsStatistics, setChannelsStatistics] = useState([])
  const [statisticsIsLoading, setStatisticsIsLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('isEnabled') === 'false') {
      navigate('/info-enabled')
    }
    if (channels.length) {
      ;(async function () {
        setStatisticsIsLoading(true)
        const response = await instance.get(api.channelStatistics)
        setStatisticsIsLoading(false)
        const { status, data } = response
        if (status === 200) {
          setChannelsStatistics(data)
        }
      })()
    }
  }, [channels])

  if (isLoading || statisticsIsLoading) {
    return <LoadingSpinner />
  }

  if (!channelsStatistics.length && !activeChannels.length) {
    return <AddChannel />
  }

  return (
    <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-3">
      {channelsStatistics.map((statistics: ChannelStatistics) => {
        const channel = activeChannels.find(
          ({ id }) => id === statistics.channelId
        )

        if (!channel) {
          return null
        }

        return (
          <ChannelStatisticsCard
            key={statistics.channelId}
            channelName={channel.channelName}
            statistics={statistics}
          />
        )
      })}
    </div>
  )
}

export default Main
