import React, { FunctionComponent } from "react";
import { NextUIProvider } from "@nextui-org/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "react-datepicker/dist/react-datepicker.css"

const App: FunctionComponent = () => {
  return (
    <NextUIProvider>
      <RouterProvider router={router} />
    </NextUIProvider>
  );
}

export default App;
