import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { TEXTS } from "../../../consts";

type Props = {
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
};

const AddNewUserModal: FunctionComponent<Props> = ({ isOpen, onOpenChange, onClose }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [newUserId, setNewUserId] = useState("");
  const [rights, setRights] = useState<string[]>([]);

  const { settings: { modal } } = TEXTS;

  const onNewUserIdChange = (value: string) => {
    isInvalid && setIsInvalid(false);
    setNewUserId(value)
  };
  const onNewUserRightsChange = (value: string[]) => setRights(value);

  const onSubmit = () => {
    if (!newUserId.trim()) {
      setIsInvalid(true);
      return;
    }

    setNewUserId("");
    setRights([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="lg" classNames={{ body: "mb-5"}}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">{modal.title}</ModalHeader>
        <ModalBody>
          <Input
            label={modal.id.label}
            labelPlacement="outside"
            classNames={{
              label: "text-base",
              base: "mb-5",
            }}
            placeholder={modal.id.placeholder}
            value={newUserId}
            variant="bordered"
            onValueChange={onNewUserIdChange}
            isInvalid={isInvalid}
          />
          <CheckboxGroup
            label={modal.role.title}
            classNames={{
              label: "font-medium"
          }}
            value={rights}
            onValueChange={onNewUserRightsChange}
          >
            <Checkbox value="watch">{modal.role.watch}</Checkbox>
            <Checkbox value="manageOthersLinks">{modal.role.manageOthersLinks}</Checkbox>
            <Checkbox value="manageOwnLinks">{modal.role.manageOwnLinks}</Checkbox>
          </CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onPress={onSubmit}>
            {modal.button}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewUserModal;