import React, { FunctionComponent, useEffect, useState } from "react";
import useCopy from "use-copy";
import { Link, Tooltip } from "@nextui-org/react";
import { LuCopy } from "react-icons/lu";
import { TEXTS } from "../../../../../consts";

type Props = {
  link: string;
};

const CopyLink: FunctionComponent<Props> = ({ link }) => {
  const { channelTable: { copyTooltip }} = TEXTS;

  const [copied, copy, setCopied] = useCopy(link);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setCopied(false);
    }
  }, [isOpen]);

  const copyText = () => {
    copy();
    setIsOpen(true);
  };

  const onOpenChange = (open: boolean) => setIsOpen(open);

  if (!link) {
    return null;
  }

  return (
    <Tooltip
      showArrow
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      closeDelay={0}
      content={copied ? copyTooltip.copied : copyTooltip.copy}
    >
      <Link onClick={copyText} size="sm" className="gap-1 cursor-pointer break-all flex items-center">
        <span><LuCopy size={15} /></span>
        <span className="text-xs">{link}</span>
      </Link>
    </Tooltip>
  );
};

export default CopyLink;