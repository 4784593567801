import React from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact  from 'highcharts-react-official';
import useBreakPoints from "../../../../../hooks/useBreakPoints";

const DayChart = ({ data }) => {
  const { isTablet } = useBreakPoints();
  const xAxisFontSize = isTablet ? 9 : 12;
  const yAxisFontSize = isTablet ? 12 : 14;

  if (!data.length) {
    return null;
  }

  const options = {
    rangeSelector: {
      enabled: false
    },
    navigator: {
      enabled: true,
      xAxis: {
        labels: {
          style: {
            fontSize: xAxisFontSize,
          },
        },
        dateTimeLabelFormats: {
          day: '%e.%m.%y',
          week: '%e.%m.%y',
          month: '%e.%m.%y',
          year: '%Y'
        }
      }
    },

    title: {
      text: undefined
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      maxZoom: 1,
      dateTimeLabelFormats: {
        day: '%e.%m.%y',
        week: '%e.%m.%y',
        month: '%e.%m.%y',
        year: '%Y'
      },
      labels: {
        style: {
          fontSize: xAxisFontSize,
        },
      },
    },
    yAxis: {
      showFirstLabel: false,
      allowDecimals: false,
      opposite: false,
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: yAxisFontSize,
        }
      }
    },
    accessibility: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      style: {
        width: '200px'
      },
      formatter: function () {
        const date = new Date(this.x);

        return date.toLocaleDateString("ru-RU", { day: 'numeric' }) + " "
            + date.toLocaleDateString("ru-RU", { month: 'short' }) + " "
            + date.toLocaleDateString("ru-RU", { year: 'numeric' }) + "г.<br>Подписались <b>" + this.y + "</b>";
      },
      shared: true,
      xDateFormat: '%d-%m-%Y'
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        showEmpty: true,
        connectNulls: true,
        fillColor: {
          linearGradient: [0, 0, 0, 70],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, 'rgba(255,255,255,0)']
          ]
        },
        lineWidth: 2,
        marker: {
          enabled: true,
          radius: 3,
          states: {
            hover: {
              enabled: true,
              radius: 3
            }
          }
        },
        shadow: false,
        states: {
          hover: {
            lineWidth: 2
          }
        },
      }
    },
    series: [{
      type: 'line',
      data: data
    }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      constructorType='stockChart'
    />
  );
};

export default DayChart;