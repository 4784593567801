import React from 'react'
import { useNavigate } from 'react-router-dom'
import TelegramLoginWidget from './TelegramLoginWidget'
import { TelegramUser } from '../../../types'
import instance, { api } from '../../../axios/config'

const LoginButton = () => {
  const navigate = useNavigate()

  const onTelegramAuth = async (user: TelegramUser) => {
    const response = await instance.post(api.auth, user)
    const { data, status } = response

    if (status === 200) {
      localStorage.setItem('access', data.access_token)
      navigate('/main')
    }
  }

  return (
    <TelegramLoginWidget
      dataOnAuth={onTelegramAuth}
      botName="app_tg_admin_login_bot"
    />
  )
}

export default LoginButton
