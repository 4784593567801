import React from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact  from 'highcharts-react-official';
import useBreakPoints from "../../../../../hooks/useBreakPoints";

const HourChart = ({ data }) => {
  const { isTablet } = useBreakPoints();
  const xAxisFontSize = isTablet ? 9 : 12;
  const yAxisFontSize = isTablet ? 12 : 14;

  if (!data.length) {
    return null;
  }

  const options = {
    title: {
      text: undefined
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: xAxisFontSize,
        },
        formatter: function () {
          return new Date(this.value).toLocaleTimeString().slice(0, 5);
        },
      },
      categories: data.map(item => item[0]),
      endOnTick: true,
      crosshair: {
        width: 1,
        color: '#cccccc',
        label: {
          enabled: false,
        }
      }
    },
    yAxis: {
      type: 'linear',
      allowDecimals: false,
      min: 0,
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: yAxisFontSize,
        },
      }
    },
    tooltip: {
      borderWidth: 1,
      formatter: function() {
        return `${new Date(this.x).toLocaleDateString()} ${new Date(this.x).toLocaleTimeString().slice(0, 5)} <br>Подписались <b>${this.y}</b>`;
      },
      shared: true
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          enabled: true,
          radius: 3,
          states: {
            hover: {
              enabled: true,
              radius: 3
            }
          }
        },
        shadow: false,
        states: {
          hover: {
            lineWidth: 2
          }
        },
      }
    },
    series: [{
      type: 'line',
      data: data.map(item => item[1]),
    }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default HourChart;