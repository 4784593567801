import React, { FunctionComponent } from "react";
import { Card, CardHeader, CardBody, Avatar, Divider } from "@nextui-org/react";
import { TEXTS } from "../../../consts";
import { getInitials } from "../../../utils";
import { Link } from "react-router-dom";
import { ChannelStatistics } from "../../../types";

type Props = {
  channelName: string;
  statistics: ChannelStatistics;
};

const ChannelStatisticsCard: FunctionComponent<Props> = ({ channelName, statistics}) => {
  const { main } = TEXTS;
  const { channelId, advBudget, advCampaignPriceTotal, pricePerSubscriber, subscribersCountTotal } = statistics;

  return (
    <Card key={channelId} shadow="sm" className="w-full">
      <CardHeader>
        <Link to={`/channels/${channelId}`} className="flex items-center gap-3 hover:opacity-80">
          <Avatar
            name={channelName || channelId}
            getInitials={getInitials}
            // src={img} TODO: Add when API will be ready
          />
          <div className="flex-1">{channelName || channelId}</div>
        </Link>
      </CardHeader>
      <Divider orientation="horizontal"/>
      <CardBody className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p>{main.subscribers}</p>
          <div className="font-medium">{subscribersCountTotal}</div>
        </div>
        <div className="flex flex-row justify-between">
          <p>{main.advertisementsCount}</p>
          <div className="font-medium">{advCampaignPriceTotal}</div>
        </div>
        <div className="flex flex-row justify-between">
          <p>{main.budget}</p>
          <div className="font-medium">{advBudget}</div>
        </div>
        <div className="flex flex-row justify-between">
          <p>{main.subscriberCost}</p>
          <div className="font-medium">{pricePerSubscriber}</div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ChannelStatisticsCard;