import { useCallback } from "react";
import { DateFilterValue } from "../../../types";

const useChangeDateFilterValueHandler = (callback: (value: DateFilterValue) => void) =>
  useCallback((value: DateFilterValue) => {
    if (value) {
      callback(value);
    } else {
      callback({ startDate: undefined , endDate: undefined });
    }
  }, []);

export default useChangeDateFilterValueHandler;