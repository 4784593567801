// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRange_calendar__zaGGM {
    position: absolute;
    z-index: 100;
    background-color: #E9E9E9;
    box-shadow: 0 3px 15px -7px rgba(66, 68, 90, 1);
}`, "",{"version":3,"sources":["webpack://./src/components/DateRange/DateRange.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,+CAA+C;AACnD","sourcesContent":[".calendar {\n    position: absolute;\n    z-index: 100;\n    background-color: #E9E9E9;\n    box-shadow: 0 3px 15px -7px rgba(66, 68, 90, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": `DateRange_calendar__zaGGM`
};
export default ___CSS_LOADER_EXPORT___;
