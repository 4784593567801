import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarContent,
  NavbarMenuToggle,
  NavbarMenu, Divider
} from "@nextui-org/react";
import Logo from "../../../../components/Logo";
import LogOutButton from "./components/LogOutButton";
import UserInfo from "../../../../components/UserInfo";
import NavbarLinks from "./components/NavbarLinks";

type Props = {
  logOut: () => void;
};

const TabletNavbar: FunctionComponent<Props> = ({ logOut }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickNavbarItem = () => {
    setIsMenuOpen(false);
  };

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      classNames={{ wrapper: "px-3"}}
    >
      <NavbarContent className="flex !justify-between">
        <Link to='/main' className='hover:opacity-80'>
          <Logo imageClassNames="w-[60px]" />
        </Link>
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"}/>
      </NavbarContent>
      <NavbarMenu className="pt-0 px-3">
        <Divider />
        <UserInfo />
        <Divider className="mb-2" />
        <NavbarLinks onClickNavbarItem={onClickNavbarItem} />
        <LogOutButton onLogOutClick={logOut} />
      </NavbarMenu>

    </Navbar>
  );
};

export default TabletNavbar;