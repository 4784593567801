import React, { ReactNode, useMemo } from 'react'
import { TableCell, TableRow } from '@nextui-org/react'
import { ColumnKey, LinkType } from '../../../types'
import { TABLE_COLUMNS } from '../../../consts'

type Options = {
  sortedItems: LinkType[]
  renderCell: (item: LinkType, columnKey: ColumnKey) => ReactNode
  budget: number
  subscribersCount: number
  pricePerSubscriber: number
  handleTogleModal: any
}
const useLinksWithSummary = ({
  sortedItems,
  renderCell,
  budget,
  subscribersCount,
  pricePerSubscriber,
  handleTogleModal,
}: Options) => {
  return useMemo(() => {
    const cellClassName =
      'text-[10px] xl:text-xs text-xs xl:text-sm font-medium'
    const rows = sortedItems.map((item) => (
      <TableRow key={item.id}>
        {TABLE_COLUMNS.map(({ key }, index) => (
          <TableCell
            key={key}
            className={`text-xs xl:text-sm ${
              index === 6 ? 'cursor-pointer' : ''
            }`}
            onClick={() =>
              index === 6 ? handleTogleModal(true, item.id) : null
            }
          >
            {item.loadingPercent < 100 &&
            (index === 8 || index === 9 || index === 10) ? (
              <span
                style={{
                  position: index === 9 ? 'absolute' : 'static',
                  top: '5px',
                  left: '-30px',
                  opacity: index === 8 || index === 10 ? 0 : 1,
                }}
              >
                {`Loading ${item.loadingPercent}%`}
              </span>
            ) : (
              renderCell(item, key as ColumnKey)
            )}
          </TableCell>
        ))}
      </TableRow>
    ))

    if (sortedItems.length) {
      rows.push(
        <TableRow
          key="summary-row"
          className="bg-background-300 h-[40px]"
        >
          {TABLE_COLUMNS.map(({ key }, index) => {
            const cellContent =
              key === 'placementChannel'
                ? 'Всего'
                : key === 'subscribersCount'
                ? subscribersCount
                : key === 'cost'
                ? budget
                : key === 'pricePerSubscriber'
                ? pricePerSubscriber
                : null

            const roundClassName =
              index === 0
                ? ' rounded-s-md'
                : index === TABLE_COLUMNS.length - 1
                ? ' rounded-e-md'
                : ''
            const className = cellClassName + roundClassName

            return (
              <TableCell
                key={key}
                className={className}
              >
                {cellContent}
              </TableCell>
            )
          })}
        </TableRow>
      )
    }

    return rows
  }, [sortedItems, budget, subscribersCount, pricePerSubscriber])
}

export default useLinksWithSummary
