import React, { FunctionComponent } from "react";
import { Link } from "@nextui-org/react";

type Props = {
  placementChannel: string;
};

const PlacementChannelLink: FunctionComponent<Props> = ({ placementChannel }) => {
  if (!placementChannel) {
    return null;
  }

  const isLink = placementChannel.startsWith("https://");

  if (isLink) {
    return (
      <Link href={placementChannel} target="_blank" size="sm" className="gap-1 cursor-pointer break-all flex items-center text-xs">
        {placementChannel}
      </Link>
    );
  }

  return (<>{placementChannel}</>);
};

export default PlacementChannelLink;