import React, { FunctionComponent } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import UserInfo from "../../../../components/UserInfo";

const Header: FunctionComponent = () => {
  const title = usePageTitle();

  return (
    <div className="flex flex-row justify-between mb-3 md:mb-5">
      <div className="text-xl font-medium">{title}</div>
      <UserInfo className="hidden lg:flex" />
    </div>
  );
};

export default Header;