import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import Auth from "./pages/auth/Auth";
import Main from "./pages/main/Main";
import AddChannel from "./pages/addChannel/AddChannel";
import ArchivedChannels from "./pages/archivedChannels/ArchivedChannels";
import Layout from "./pages/layout/Layout";
import Channels from "./pages/channels/Channels";
import Channel from "./pages/channels/Channel";
import Settings from "./pages/settings/Settings";
import InfoEnabled from "./pages/InfoEnabled";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />} path="/">
        <Route index element={<Navigate to="/main" />} />
        <Route path="main" element={<Main />} />
        <Route path="channels" element={<Channels />} >
          <Route path="/channels/:id" element={<Channel />} />
          <Route path="/channels/:id/settings" element={<Settings />} />
        </Route>
        <Route path="add-channel" element={<AddChannel />} />
        <Route path="archive-channels" element={<ArchivedChannels />} />
        <Route path="*" element={<Navigate to="/main" />} />
      </Route>
      <Route path="/login" element={<Auth />}/>
      <Route path="/info-enabled" element={<InfoEnabled />}/>
    </>
  )
);