import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Header from './components/Header/Header'
import instance, { api } from '../../axios/config'
import { ChannelsContext } from '../../context/ChannelsContext'
import { UserContext } from '../../context/UserContext'
import { ChannelType } from '../../types'
import useEventSource from '../../hooks/useEventSource'
import useGetUser from '../../hooks/useGetUser'
import LoadingSpinner from '../../components/LoadingSpinner'

const Layout: FunctionComponent = () => {
  const [isAuthorized, setIsAuthorized] = useState(
    !!localStorage.getItem('access')
  )
  const [channels, setChannels] = useState<ChannelType[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const onLogOut = () => {
    setIsAuthorized(false)
    localStorage.removeItem('access')
  }

  const { user, loading: userIsLoading } = useGetUser(onLogOut)

  const loadChannels = useCallback(async () => {
    setIsLoading(true)
    const response = await instance.get(api.channels)
    setIsLoading(false)
    const { status, data } = response
    if (status === 200) {
      setChannels(data)
    }
  }, [])

  useEventSource({ loadChannels, user, isLoading: userIsLoading })

  useEffect(() => {
    if (!isAuthorized) {
      return navigate('/login')
    }

    if (user.id) {
      user.isEnabled ? loadChannels() : navigate('/info-enabled')
    }
  }, [user.id, isAuthorized, user.isEnabled])

  if (userIsLoading) {
    return <LoadingSpinner />
  }

  return (
    <UserContext.Provider value={user}>
      <ChannelsContext.Provider value={{ channels, isLoading, loadChannels }}>
        <div className="min-h-screen h-fit bg-background">
          <Navbar logOut={onLogOut} />
          <main className="py-2 lg:py-7 px-3 md:px-4 lg:ml-[64px] xl:ml-[209px] min-h-screen lg:border-l-2 lg:border-white">
            <Header />
            <Outlet />
          </main>
        </div>
      </ChannelsContext.Provider>
    </UserContext.Provider>
  )
}

export default Layout
