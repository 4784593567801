import React, {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react'
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  useDisclosure,
  SortDescriptor,
  Spinner,
} from '@nextui-org/react'
import { TABLE_COLUMNS, TEXTS } from '../../../../consts'
import {
  DateFilterValue,
  EditLinkType,
  LinkSearchParams,
  LinkType,
  PageSize,
} from '../../../../types'
import useChangeTextFilterValueHandler from '../../hooks/useChangeTextFilterValueHandler'
import useChangeDateFilterValueHandler from '../../hooks/useChangeDateFilterValueHandler'
import useRenderCell from '../../hooks/useRenderCell'
import StatisticsModal from '../Modals/StatisticsModal/StatisticsModal'
import instance, { api } from '../../../../axios/config'
import useSortLinks from '../../hooks/useSortLinks'
import useTableTopContent from '../../hooks/useTableTopContent'
import useTableBottomContent from '../../hooks/useTableBottomContent'
import LinkModal from '../Modals/LinkModal'
import useBreakPoints from '../../../../hooks/useBreakPoints'
import TabletChannelLinks from './TabletChannelLinks'
import useLinksWithSummary from '../../hooks/useLinksWithSummary'
import Modal from '../../../../components/Modal/Modal'

type Props = {
  id: string
  isLoading: boolean
  links: LinkType[]
  linksCount: number
  loadLinks: (options: LinkSearchParams) => void
  budget: number
  subscribersCount: number
  pricePerSubscriber: number
}

const ChannelLinksTable: FunctionComponent<Props> = ({
  id,
  isLoading,
  links,
  linksCount,
  loadLinks,
  budget,
  subscribersCount,
  pricePerSubscriber,
}) => {
  const {
    channelTable: { noFilteredData, editLinkModal },
  } = TEXTS

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onOpenChange: onEditModalOpenOpenChange,
    onClose: onEditModalClose,
  } = useDisclosure()
  const {
    isOpen: isStatModalOpen,
    onOpen: onStatModalOpen,
    onOpenChange: onStatModalOpenOpenChange,
    onClose: onStatModalClose,
  } = useDisclosure()
  const linkRef = useRef<LinkType>()
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const didMount = useRef(false)

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'publishDate',
    direction: 'ascending',
  })
  const [openModal, setOpenModal] = useState(false)
  const [idLink, setIdLink] = useState('')
  const [pageSize, setPageSize] = useState<PageSize>('50')
  const [page, setPage] = useState(1)

  const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>({
    startDate: undefined,
    endDate: undefined,
  })
  const [showArchivedLinks, setShowArchivedLinks] = useState(false)
  const [showAllOwnersLinks, setShowAllOwnersLinks] = useState(false)
  const [titleFilterValue, setTitleFilterValue] = useState('')
  const [tagsFilterValue, setTagsFilterValue] = useState('')
  const [linkFilterValue, setLinkFilterValue] = useState('')
  const [budgetToFilterValue, setBudgetToFilterValue] = useState('')
  const [budgetFromFilterValue, setBudgetFromFilterValue] = useState('')
  const [subscribersToFilterValue, setSubscribersToFilterValue] = useState('')
  const [subscribersFromFilterValue, setSubscribersFromFilterValue] =
    useState('')

  const { isTablet } = useBreakPoints()

  const pagesCount = Math.ceil(linksCount / (Number(pageSize) || linksCount))
  const skip = (page - 1) * (Number(pageSize) || 0)

  const loadLinksWithFilters = () => {
    loadLinks({
      startDate: dateFilterValue.startDate?.toISOString(),
      endDate: dateFilterValue.endDate?.toISOString(),
      linkName: titleFilterValue || undefined,
      tagName: tagsFilterValue || undefined,
      linkValue: linkFilterValue || undefined,
      budgetFrom: budgetFromFilterValue || undefined,
      budgetTo: budgetToFilterValue || undefined,
      pricePerSubscriberFrom: subscribersFromFilterValue || undefined,
      pricePerSubscriberTo: subscribersToFilterValue || undefined,
      isArchived: showArchivedLinks,
      ownedByUser: !showAllOwnersLinks,
      take: pageSize,
      skip,
    })
  }

  useEffect(() => {
    setPage(1)
  }, [
    showAllOwnersLinks,
    showArchivedLinks,
    titleFilterValue,
    dateFilterValue,
    tagsFilterValue,
    linkFilterValue,
    budgetFromFilterValue,
    budgetToFilterValue,
    subscribersFromFilterValue,
    subscribersToFilterValue,
  ])

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    if (didMount.current) {
      timerRef.current = setTimeout(() => {
        loadLinksWithFilters()
      }, 500)
    } else {
      didMount.current = true
    }
  }, [
    page,
    pageSize,
    showAllOwnersLinks,
    showArchivedLinks,
    titleFilterValue,
    dateFilterValue,
    tagsFilterValue,
    linkFilterValue,
    budgetFromFilterValue,
    budgetToFilterValue,
    subscribersFromFilterValue,
    subscribersToFilterValue,
  ])

  const sortedItems = useSortLinks({ items: links, sortDescriptor })

  const onTitleSearchChange =
    useChangeTextFilterValueHandler(setTitleFilterValue)
  const onLinkSearchChange = useChangeTextFilterValueHandler(setLinkFilterValue)
  const onTagsSearchChange = useChangeTextFilterValueHandler(setTagsFilterValue)
  const onBudgetFromSearchChange = useChangeTextFilterValueHandler(
    setBudgetFromFilterValue
  )
  const onBudgetToSearchChange = useChangeTextFilterValueHandler(
    setBudgetToFilterValue
  )
  const onSubscribersFromSearchChange = useChangeTextFilterValueHandler(
    setSubscribersFromFilterValue
  )
  const onSubscribersToSearchChange = useChangeTextFilterValueHandler(
    setSubscribersToFilterValue
  )
  const onDateSearchChange = useChangeDateFilterValueHandler(setDateFilterValue)
  const onShowArchivedLinksChanges = (isSelected: boolean) =>
    setShowArchivedLinks(isSelected)
  const onshowAllOwnersLinksChanges = (isSelected: boolean) =>
    setShowAllOwnersLinks(isSelected)

  const handleClickStatistics = useCallback(
    (id: string) => {
      linkRef.current = links.find((link) => link.id === id)
      onStatModalOpen()
    },
    [links]
  )

  const handleClickDelete = useCallback(async (id: string) => {
    const response = await instance.delete(`${api.links}/${id}`)
    const { status } = response
    if (status === 200) {
      loadLinksWithFilters()
    }
  }, [])

  const handleClickEdit = useCallback(
    (id: string) => {
      linkRef.current = links.find((link) => link.id === id)
      onEditModalOpen()
    },
    [links]
  )

  const handleClickArchive = useCallback(
    async (id: string) => {
      const link = links.find((link) => link.id === id)
      const data = {
        ...link,
        cost: link?.cost || 0,
        tagIds: link?.tags.map(({ id }) => id) || [],
        newTagNames: [],
        isArchived: (!link?.isArchived).toString(),
        tags: undefined,
      }
      const response = await instance.put(`${api.links}/${id}`, data)
      const { status } = response
      if (status === 200) {
        loadLinksWithFilters()
      }
    },
    [links]
  )

  const onPageSizeChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(e.target.value as PageSize)
    setPage(1)
  }, [])

  const onPageChange = useCallback((value: number) => {
    setPage(value)
  }, [])

  const editLink = async (data: EditLinkType, id: string) => {
    const response = await instance.put(`${api.links}/${id}`, data)
    const { status } = response
    if (status === 200) {
      loadLinksWithFilters()
    }
  }

  const handleTogleModal = (value: boolean, linkId: string) => {
    setOpenModal(value)
    setIdLink(linkId)
  }

  const renderCell = useRenderCell({
    showArchivedLinks,
    handleClickStatistics,
    handleClickDelete,
    handleClickEdit,
    handleClickArchive,
  })

  const topContent = useTableTopContent({
    dateFilterValue,
    onDateSearchChange,
    titleFilterValue,
    onTitleSearchChange,
    tagsFilterValue,
    onTagsSearchChange,
    linkFilterValue,
    onLinkSearchChange,
    budgetFromFilterValue,
    onBudgetFromSearchChange,
    budgetToFilterValue,
    onBudgetToSearchChange,
    subscribersFromFilterValue,
    onSubscribersFromSearchChange,
    subscribersToFilterValue,
    onSubscribersToSearchChange,
    showArchivedLinks,
    onShowArchivedLinksChanges,
    showAllOwnersLinks,
    onshowAllOwnersLinksChanges,
    sortDescriptor,
    setSortDescriptor,
  })

  const bottomContent = useTableBottomContent({
    page,
    pageSize,
    onPageSizeChange,
    onPageChange,
    pagesCount,
  })

  const tableRows = useLinksWithSummary({
    sortedItems,
    renderCell,
    budget,
    subscribersCount,
    pricePerSubscriber,
    handleTogleModal,
  })

  return (
    <>
      <StatisticsModal
        link={linkRef.current}
        isOpen={isStatModalOpen}
        onClose={onStatModalClose}
        onOpenChange={onStatModalOpenOpenChange}
      />

      <LinkModal
        title={editLinkModal}
        channelId={id}
        link={linkRef.current}
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        onOpenChange={onEditModalOpenOpenChange}
        onSubmit={editLink}
      />

      {isTablet ? (
        <TabletChannelLinks
          links={sortedItems}
          renderCell={renderCell}
          topContent={topContent}
          bottomContent={bottomContent}
          budget={budget}
          subscribersCount={subscribersCount}
          pricePerSubscriber={pricePerSubscriber}
          handleTogleModal={handleTogleModal}
        />
      ) : (
        <Table
          aria-label="Channel links table"
          sortDescriptor={sortDescriptor}
          onSortChange={setSortDescriptor}
          topContent={topContent}
          topContentPlacement="inside"
          bottomContent={bottomContent}
          bottomContentPlacement="outside"
          classNames={{
            th: 'px-2',
            td: 'px-1 md:px-2',
          }}
        >
          <TableHeader>
            {TABLE_COLUMNS.map(({ key, title }) => (
              <TableColumn
                key={key}
                allowsSorting={key !== 'actions'}
                className="text-[10px] xl:text-xs"
              >
                {title}
              </TableColumn>
            ))}
          </TableHeader>
          <TableBody
            items={sortedItems}
            loadingState={isLoading ? 'loading' : 'idle'}
            loadingContent={<Spinner className="mt-5" />}
            emptyContent={noFilteredData}
          >
            {tableRows}
          </TableBody>
        </Table>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkId={idLink}
      />
    </>
  )
}

export default ChannelLinksTable
