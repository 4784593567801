import React, { FunctionComponent } from 'react'
import { TEXTS } from '../../consts'
import Logo from '../../components/Logo'
import LoginButton from './components/LoginButton'

const Auth: FunctionComponent = () => {
  const { authPage: authPageTexts } = TEXTS
  const {
    title: { start: titleStart, end: titleEnd },
    subtitle,
  } = authPageTexts

  return (
    <div className="h-screen min-h-[450px]">
      <div className="px-4 mx-auto max-w-lg md:max-w-full flex flex-col items-center justify-center md:justify-start md:py-unit-10 gap-6 h-full pb-unit-10 z-1 relative">
        <Logo imageClassNames="w-[150px] sm:w-[300px] md:w-[400px]" />
        <div className="text-center">
          <div className="mb-unit-6 sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl font-medium">
            {titleStart}
            <span className="text-primary">{titleEnd}</span>
          </div>
          <div className="text-xs sm:text-sm md:text-base xl:text-lg opacity-70">
            {subtitle}
          </div>
        </div>
        <div
          className="md:mt-7"
          id="telegram-widget-container"
        >
          <LoginButton />
        </div>
      </div>
    </div>
  )
}

export default Auth
