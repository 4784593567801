import React, { FunctionComponent, useContext } from "react";
import { Card, CardBody, Listbox, ListboxItem } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { BsArchive } from "react-icons/bs";
import { TEXTS } from "../../../consts";
import instance, { api } from "../../../axios/config";
import { ChannelsContext } from "../../../context/ChannelsContext";

type Props = {
  id: string;
};

const ChannelActions: FunctionComponent<Props> = ({ id }) => {
  const { loadChannels } = useContext(ChannelsContext);
  const { settings } = TEXTS;

  const navigate = useNavigate();

  const archiveChannel = async () => {
    const response = await instance.post(api.archiveChannel.replace(':id', id));
    const { status } = response;
    if (status === 201) {
      loadChannels();
      navigate('/archive-channels');
    }
  };

  return (
    <Card  shadow="sm" className="w-full row-span-1 col-span-2 md:col-span-1">
      <CardBody>
        <Listbox
          aria-label="Actions"
          variant="flat"
        >
          <ListboxItem
            key="archiveChannel"
            color="primary"
            onClick={archiveChannel}
            startContent={<BsArchive size={20} />}
          >
            {settings.archiveChannel}
          </ListboxItem>
        </Listbox>
      </CardBody>
    </Card>
  );
};

export default ChannelActions;