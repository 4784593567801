import React, { ChangeEvent, useMemo } from "react";
import { Pagination } from "@nextui-org/react";
import { PAGE_SELECT, TEXTS } from "../../../consts";
import { PageSize } from "../../../types";

type Options = {
  pageSize: PageSize;
  page: number;
  onPageSizeChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onPageChange: (value: number) => void;
  pagesCount: number;
};

const useTableBottomContent = ({ page, pageSize, onPageSizeChange, onPageChange, pagesCount }: Options) => {
  const { channelTable: { linksPerPage } } = TEXTS;

  return useMemo(() => {
    return (
      <div className="flex justify-between items-center">
        <label className="flex items-center text-default-400 text-small">
          {linksPerPage}
          <select
            className="bg-transparent outline-none text-default-400 text-small"
            onChange={onPageSizeChange}
          >
            {Object.entries(PAGE_SELECT).map(([key, value]) =>
              <option key={key} value={key}>{value}</option>
            )}
          </select>
        </label>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pagesCount}
          onChange={onPageChange}
        />
      </div>
    );
  }, [page, pageSize, pagesCount]);
};

export default useTableBottomContent;