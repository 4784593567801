import React, { useCallback, useContext } from 'react'
import { ColumnKey, LinkType, Tag } from '../../../types'
import CopyLink from '../components/ChannelLinksTable/components/CopyLink'
import LinkActions from '../components/ChannelLinksTable/components/LinkActions'
import { UserContext } from '../../../context/UserContext'
import PlacementChannelLink from '../components/ChannelLinksTable/components/PlacementChannelLink'

type Options = {
  handleClickEdit: (id: string) => void
  handleClickArchive: (id: string) => void
  handleClickStatistics: (id: string) => void
  handleClickDelete: (id: string) => void
  showArchivedLinks: boolean
}

const useRenderCell = ({
  showArchivedLinks,
  handleClickStatistics,
  handleClickDelete,
  handleClickEdit,
  handleClickArchive,
}: Options) => {
  const { id: userId } = useContext(UserContext)

  return useCallback(
    (channelLink: LinkType, columnKey: ColumnKey) => {
      const isOwn = userId === channelLink.ownerUserId

      switch (columnKey) {
        case 'placementChannel':
          return (
            <PlacementChannelLink
              placementChannel={channelLink.placementChannel}
            />
          )
        case 'publishDate':
          return new Date(channelLink[columnKey]).toLocaleDateString()
        case 'tags':
          return (
            channelLink[columnKey]?.map((tag: Tag) => tag.name).join(', ') ||
            null
          )
        case 'link':
          return <CopyLink link={channelLink.link || ''} />
        case 'hasApplicationMembership':
          return channelLink.hasApplicationMembership ? '✅' : '❌'
        case 'actions':
          return (
            <LinkActions
              id={channelLink.id}
              isOwn={isOwn}
              showArchivedLinks={showArchivedLinks}
              handleClickStatistics={handleClickStatistics}
              handleClickDelete={handleClickDelete}
              handleClickEdit={handleClickEdit}
              handleClickArchive={handleClickArchive}
            />
          )
        default:
          return channelLink[columnKey]
      }
    },
    [
      handleClickStatistics,
      handleClickDelete,
      handleClickEdit,
      handleClickArchive,
    ]
  )
}

export default useRenderCell
