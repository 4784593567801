import { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { DecodedAccessToken, User } from '../types'
import instance, { api } from '../axios/config'

type logOut = () => void

const useGetUser = (logOut: logOut) => {
  const token = localStorage.getItem('access')
  const decodedAccessToken: DecodedAccessToken = token
    ? jwt_decode(token)
    : ({} as DecodedAccessToken)
  const { tgId, username, sub } = decodedAccessToken

  const [user, setUser] = useState<User>({
    id: '',
    createdAt: '',
    tgUserId: tgId,
    tgUsername: username,
    tgUserLink: '',
    tgPhotoUrl: '',
    tgPhoto: '',
    isEnabled: false,
  })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      const response = await instance.get(api.users.replace(':id', sub))
      setLoading(false)
      const { data, status } = response

      if (status === 200 && data) {
        setUser(data)
        localStorage.setItem('isEnabled', response.data.isEnabled)
      } else {
        logOut()
      }
    })()
  }, [])

  return { user, loading }
}

export default useGetUser
